<template>
    <!-- navbar-->
    <div id="shopnavigation">
        <nav class="navbar navbar-expand-lg navbar-top">
            <div class="container">
                <router-link to="/" class="navbar-brand home">
                    <img src="../assets/img/Logo-Chang-Asia-Shop-7-navbar.png" height="90px" alt="Logo Chang Asia Shop"
                        class="d-none d-md-inline-block" />
                    <img src="../assets/img/Logo-Chang-Asia-Shop-7-navbar.png" height="50px" alt="Logo Chang Asia Shop"
                        class="d-inline-block d-md-none">
                    <span class="sr-only">Gehe zur Homepage Chang Asia Shop</span></router-link>
                <div class="navbar-buttons">
                    <button type="button" data-toggle="collapse" data-target="#navigation"
                        class="btn btn-outline-secondary navbar-toggler" aria-controls="navigation"
                        aria-expanded="false" aria-label="Toggle navigation" @click.stop="toggleNavbar()">
                        <span class="sr-only">Toggle navigation</span><i class="fa fa-align-justify"></i>
                    </button>
                    <router-link to="/warenkorb" class="btn btn-outline-secondary navbar-toggler"><i
                            class="fa fa-shopping-cart"></i><span>{{ warenkorbanzahl }}</span></router-link>
                </div>
                <div id="navigation" class="collapse navbar-collapse" v-bind:class="{ 'show': show }">
                    <div class="d-lg-none mt-4">
                        <div class="row align-items-center-util">
                            <div class="col d-flex justify-content-start align-items-end"><span
                                    class="mobilemenu-title">MEN&Uuml;</span></div>
                            <div class="col d-flex justify-content-end align-items-end">
                                <div @click="toggleNavbar()" class="mobilemenu-close"><span class="fa fa-close"></span>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <ul class="navbar-nav nav-scrollbar-inner mr-auto">
                        <li v-for="warengruppe in warengruppen" :key="warengruppe.name" class="nav-item">
                            <a href="#" class="nav-link" @click.prevent="routeToWarengruppe(warengruppe.name)">{{
                                warengruppe.bezeichnung }}</a>
                        </li>
                        <li class="d-lg-none">
                            <hr />
                        </li>
                        <li class="nav-item d-lg-none" v-if="isAbgemeldet">
                            <a href="#" class="nav-link" @click.prevent="routeTo('Login')">Anmelden</a>
                        </li>
                        <li class="nav-item d-lg-none" v-if="isAbgemeldet">
                            <a href="#" class="nav-link" @click.prevent="routeTo('Registrierung')">Registrierung</a>
                        </li>
                        <li class="nav-item d-lg-none" v-if="isAngemeldet">
                            <span class="displaybenutzername">Angemeldet: {{ benutzername }}</span>
                        </li>
                        <li class="nav-item d-lg-none" v-if="isAngemeldet">
                            <a href="#" class="nav-link" @click.prevent="abmelden()">Abmelden</a>
                        </li>
                        <li class="nav-item d-lg-none"><a href="#" class="nav-link"
                                @click.prevent="routeTo('Kontakt')">Kontakt</a></li>
                    </ul>
                    <div class="navbar-buttons d-flex justify-content-end">
                        <div id="basket-overview" class="navbar-collapse collapse d-none d-lg-block">
                            <router-link :to="{ name: 'Warenkorb' }" class="btn btn-primary navbar-btn"><i
                                    class="fa fa-shopping-cart"></i><span>{{ warenkorbanzahl }}</span></router-link>
                        </div>
                        <notifications group="warenkorb" position="top right" />
                    </div>
                </div>
            </div>
        </nav>
        <nav class="navbar navbar-expand-lg py-1">
            <div class="d-flex justify-content-center container">
                <div>
                    <div class="form-inline my-0 my-lg-0 flex-nowrap">
                        <input class="form-control mr-2 mb-1 suchfeld" type="text" placeholder="Suchbegriff"
                            aria-label="Search" v-model="suche" v-on:keyup.enter="routeToSuche()">
                        <button class="btn btn-outline-primary mt-0 mb-1" type="button"
                            @click="routeToSuche()">Suchen</button>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { removeScrollPosition } from "@/js/scrollpositionhelper.js"

export default {
    name: "ShopHeaderNavigation",
    data() {
        return {
            show: false,
            suche: "",
        }
    },
    methods: {
        routeToSuche() {
            this.show = false;
            let navPoint = {
                name: 'ProduktlisteSuche',
                params: {
                    suchtext: this.suche,
                },
                query: {
                    pg: 1, pc: 0, st: "NAME"
                }
            };
            this.navigateToProduktliste(navPoint);
        },
        toggleNavbar() {
            this.show = !this.show
        },
        routeToWarengruppe(warengruppe) {
            this.show = false;
            console.log("before removeScrollPosition: fullPath=" + this.$route.fullPath);
            console.log("routeToWarengruppe resolveName: " + this.$router.resolve({ name: "ProduktlisteWarengruppe", params: { warengruppe: warengruppe } }).route.path);
            ("ProduktlisteWarengruppe");
            this.suche = "";
            let navPoint = {
                name: 'ProduktlisteWarengruppe',
                params: {
                    warengruppe: warengruppe
                },
                query: {
                    pg: 1, pc: 0, st: "NAME"
                }
            };
            this.navigateToProduktliste(navPoint);
        },
        navigateToProduktliste(navPoint) {
            // Check to avoid NavigationDuplicated-Error
            let newRoute = this.$router.resolve(navPoint).route.fullPath;
            let currentRoute = this.$route.fullPath;
            if (newRoute !== currentRoute) {
                // Entferne ggf. vorhandene Scrollposition, da bei Ansprung auf xy 0,0 gesprungen werden soll
                removeScrollPosition(newRoute);
                // Führe die Navigation aus
                this.$router.push(navPoint);
            }
        },
        routeTo(destination) {
            this.show = false;
            if (destination === 'Login') {
                this.$router.push({
                    name: 'Login',
                    params: {
                        herkunft: this.$route.name,
                        warengruppe: this.$route.params.warengruppe,
                        artikelid: this.$route.params.artikelid,
                    },
                })
            } else {
                this.$router.push({
                    name: destination
                });
            }
        },
        abmelden() {
            this.show = false;
            this.$store.dispatch("abmelden");
        }
    },
    computed: {
        warenkorbanzahl() {
            return this.$store.getters.getWarenkorbAnzahl;
        },
        warengruppen() {
            return this.$store.getters["keyparameter/getWarengruppen"];
        },
        isAngemeldet() {
            return this.$store.getters.isAngemeldet;
        },
        isAbgemeldet() {
            return !this.$store.getters.isAngemeldet;
        },
        benutzername() {
            return this.$store.getters.getBenutzername;
        },
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
hr {
    margin-top: 4px;
    margin-bottom: 4px;
}

.displaybenutzername {
    padding-left: 15px;
    font-size: 0.75rem;
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav>.nav-item>.nav-link {
        padding: 5px 15px !important;
    }
}

.mobilemenu-title {
    font-size: 0.9rem;
    padding-left: 15px;
    color: #555555;
}

.mobilemenu-close {
    font-size: 1.0rem;
}

.navbar-top {
    border-bottom: 0;
}

.suchfeld {
    width: 25rem !important;
}

@media (max-width: 991.98px) {
    .suchfeld {
        width: 20rem !important;
    }
}

@media (max-width: 575.98px) {
    .suchfeld {
        width: 18rem !important;
    }
}

@media (max-width: 389.98px) {
    .suchfeld {
        width: 15rem !important;
    }
}
</style>
