<template>
  <div id="basket" class="col-lg-12">
    <div class="box">
      <!--<form>-->
      <h1>Warenkorb</h1>
      <p class="text-muted" v-if="isStatusNormal">
        Sie haben aktuell {{ warenkorbanzahl }} Produkte im Warenkorb.
      </p>
      <p class="text-danger" v-if="isStatusPruefen">
        Der Warenkorbinhalt hat sich geändert. Bitte überprüfen Sie die Daten.
      </p>
      <!-- Warenkorb ab MD-Size -->
      <div class="table-responsive d-none d-md-block">
        <table class="table">
          <thead>
            <tr>
              <th colspan="2">Produkt</th>
              <th>Menge</th>
              <th class="text-right">Einzelpreis</th>
              <th class="text-right">inkl. MwSt</th>
              <th colspan="1" class="text-right">Gesamt</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="position in warenkorbpositionen" :key="position.id">
              <td>
                <router-link
                  :to="{
                    name: 'Produktdetail',
                    params: { warengruppe: position.warengruppe , artikelid: position.artikelid },
                  }"
                  ><img
                    :src="getProduktListImageWithDefault(position.img)"
                    :alt="position.name"
                /></router-link>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'Produktdetail',
                    params: { warengruppe: position.warengruppe , artikelid: position.artikelid },
                  }"
                  >{{ position.name }}</router-link
                >
                <div v-if="istBestellmengeZuviel(position)"><span class="text-danger">Sie haben den Artikel {{ position.menge }} Mal ausgewählt.<br />Leider ist diese Anzahl nicht mehr verfügbar.<br />Bitte wählen Sie eine verfügbare Menge aus.</span></div>
                <div v-if="istArtikelAusverkauft(position)"><span class="text-danger">Der Artikel ist nicht mehr verfügbar.<br />Bitte entfernen Sie den Artikel aus dem Warenkorb.</span></div>
              </td>
              <td>
                <select
                  v-model="position.menge"
                  @change="changeMenge($event, position)"
                >
                  <!-- <option v-for="n in Math.min(position.verfuegbar,30)" :key="n">{{ n }}</option> -->
                  <option v-for="n in getAuswahlmenge(position.verfuegbar)" :key="n">{{ n }}</option>
                </select>
              </td>
              <td class="text-right">
                {{ position.bruttopreis | betrag }} &euro;
              </td>
              <td class="text-right">{{ position.umsatzsteuersatz }} %</td>
              <td class="text-right">
                {{ position.gesamtpreis | betrag }} &euro;
              </td>
              <td>
                <button type="button"
                  class="btn btn-sm btn-danger"
                  @click="entfernePosition(position)"
                >
                  <span><i class="fa fa-trash-o"></i></span>
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="5" class="warenkorb-gesamtpreis">
                Gesamtpreis inkl. MwSt. zzgl. Versandkosten
              </th>
              <th colspan="1" class="text-right warenkorb-gesamtpreis">
                {{ summe | betrag }} &euro;
              </th>
              <th class="warenkorb-gesamtpreis"></th>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- Ende Warenkorb ab MD-Size -->
      <!-- Warenkorb Small Size -->
      <div class="table-responsive d-block d-md-none">
        <table class="table">
          <thead>
            <tr>
              <th colspan="1">Produkt</th>
              <th></th>
              <th colspan="2" class="text-right">Gesamt</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="position in warenkorbpositionen" :key="position.id">
              <td>
                <router-link
                  :to="{
                    name: 'Produktdetail',
                    params: { warengruppe: position.warengruppe , artikelid: position.artikelid },
                  }"
                  ><img
                    :src="getProduktListImageWithDefault(position.img)"
                    :alt="position.name"
                /></router-link>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'Produktdetail',
                    params: { warengruppe: position.warengruppe , artikelid: position.artikelid },
                  }"
                  >{{ position.name }}</router-link><br />
                  <span>Einzelpreis&nbsp;</span><span class="text-nowrap">{{ position.bruttopreis | betrag }} &euro;</span><br />
                  <span>Menge <select
                  v-model="position.menge"
                  @change="changeMenge($event, position)"
                >
                  <!-- <option v-for="n in Math.min(position.verfuegbar,30)" :key="n">{{ n }}</option> -->
                  <option v-for="n in getAuswahlmenge(position.verfuegbar)" :key="n">{{ n }}</option>
                </select></span><br />
                <div v-if="istBestellmengeZuviel(position)"><span class="text-danger">Sie haben den Artikel {{ position.menge }} Mal ausgewählt.<br />Leider ist diese Anzahl nicht mehr verfügbar.<br />Bitte wählen Sie eine verfügbare Menge aus.</span></div>
                <div v-if="istArtikelAusverkauft(position)"><span class="text-danger">Der Artikel ist nicht mehr verfügbar.<br />Bitte entfernen Sie den Artikel aus dem Warenkorb.</span></div>
              </td>
              <td class="text-right" colspan="2">
                <span class="text-nowrap">{{ position.gesamtpreis | betrag }} &euro;</span>
              </td>
              <td>
                <!-- <button
                  class="btn btn-sm btn-danger"
                  @click="entfernePosition(position)"
                > -->
                  <span class="text-danger" @click="entfernePosition(position)"><i class="fa fa-trash-o"></i></span>
                <!-- </button> -->
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2" class="warenkorb-gesamtpreis">
                Gesamtpreis<br />inkl. MwSt.<br />zzgl. Versandkosten
              </th>
              <th colspan="2" class="text-right warenkorb-gesamtpreis">
                <span class="text-nowrap">{{ summe | betrag }} &euro;</span>
              </th>
              <th class="warenkorb-gesamtpreis"></th>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- Ende Warenkorb Small Size -->
      <div
        class="
          box-footer
          d-flex
          justify-content-between
          flex-lg-row
        "
      >
        <div class="left">
          <router-link
            :to="{
              name: 'Home'
            }"
            class="btn btn-outline-secondary"
            ><i class="fa fa-chevron-left"></i> Einkaufen</router-link
          >
        </div>
        <div class="right">
          <button type="button"
            class="btn btn-primary"
            :disabled="!isZurKasseAktiv"
            @click="zurKasse"
          >
            Zur Kasse <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div> 
<!--       <div
        class="
          box-footer
          d-flex
          justify-content-between
          flex-column flex-lg-row
        "
      ><div class="row">
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
          <router-link
            :to="{
              name: 'Home'
            }"
            class="btn btn-outline-secondary"
            ><i class="fa fa-chevron-left"></i> Zurück</router-link
          >
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
          <button
            class="btn btn-primary"
            :disabled="!isZurKasseAktiv"
            @click="zurKasse"
          >
            Zur Kasse <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
      </div>  -->
      <!--</form>-->
    </div>
<!--     <div class="box .d-block .d-md-none">
      <h2>XS und SM</h2>
    </div> -->
    <!-- /.box-->
  </div>
</template>
<script>
import { produktmethods } from "@/mixins/produkt_methods.js";
import { standardfilters } from "@/mixins/standard_filters.js";
import { warenkorballgemein } from "@/mixins/warenkorb_allgemein.js";

export default {
  name: "WarenkorbBox",
  mixins: [produktmethods, standardfilters, warenkorballgemein],
  props: { status: String },
  data() {
    return { auswahlmenge: [1,2,3,4,5,6,7,8,9,10,15,20,25,30,35,40,45,50,60,70,80,90,100] }
  },
  computed: {
    isStatusNormal() {
      console.log("WarenkorbBox status = " + this.status);
      return this.status === "normal" || this.status == undefined;
    },
    isStatusPruefen() {
      return this.status === "pruefen";
    },
    warenkorbanzahl() {
      return this.$store.getters.getWarenkorbAnzahl;
    },
    warenkorbpositionen() {
      return this.$store.getters.getWarenkorbPositionen;
    },
    gesamtpreis() {
      return this.$store.getters.getWarenkorbGesamtpreis;
    },
    summe() {
      return this.$store.getters.getWarenkorbSumme;
    },
    versandkosten() {
      return this.$store.getters.getWarenkorbVersandkosten;
    },
    isZurKasseAktiv() {
       if (this.warenkorbanzahl <= 0) {
        return false;
       }
       for (let position of this.warenkorbpositionen) {
        if (this.istBestellmengeZuviel(position) || this.istArtikelAusverkauft(position)) {
          return false;
        }
       }
       return true;
    },
  },
  methods: {
    getAuswahlmenge(verfuegbareMenge) {
      console.log("verfüg. Menge: " + verfuegbareMenge);
      console.log("Auswahlmenge: " + this.auswahlmenge);
      let resultArray = this.auswahlmenge.filter(function(item) { return item < verfuegbareMenge});
      if (verfuegbareMenge < 100) {
        resultArray.push(verfuegbareMenge);
      }
      return resultArray;
    },
    changeMenge(event, position) {
      let mengeNeu = parseInt(event.target.value);
      this.$store.dispatch("ersetzeWarenkorbPosition", {
        artikelid: position.artikelid,
        menge: mengeNeu,
        bruttopreis: position.bruttopreis,
      }).then(function() {}).catch(err => {console.log(err)});
    },
    entfernePosition(position) {
      this.$store.dispatch("entferneWarenkorbPosition", {
        artikelid: position.artikelid,
      }).then(function() {}).catch(err => {console.log(err)});
    },
    zurKasse() {
      var self = this;
      this.$store.dispatch("loadSession").then(function () {
        console.log("zurKasse loadSession then");
        if (self.$store.getters.isAngemeldet) {
          self.$router.push({ name: "BestellungAdresse" });
        } else {
          self.$router.push({ name: "BestellungAnmeldung" });
        }
      });
    },
    istBestellmengeZuviel(position) {
      return position.menge > position.verfuegbar && position.verfuegbar > 0;
    },
    istArtikelAusverkauft(position) {
      return position.menge > position.verfuegbar && position.verfuegbar <= 0;
    }
  },
  created() {
    console.log("WarenkorbBox created");
  },
  mounted() {
    console.log("WarenkorbBox mounted")
    this.$store.dispatch("loadWarenkorb").then(function() {}).catch(err => {console.log(err)});
  },
};
</script>
<style scoped>
.nav-link {
  padding: 0;
}
.clickicon {
  cursor: pointer;
}

.warenkorb-versandkosten {
  border-top: 0px solid white;
  padding: 3px 12px;
}
.warenkorb-gesamtpreis {
  border-top: 1px solid #dee2e6;
  border-bottom: 5px double #dee2e6;
  padding: 3px 12px;
}

.table tfoot tr th {
  font-size: 0.75rem;
}
</style>