<template>
    <ProduktlisteLayout :listentyp="listentyp" :warengruppe="this.$route.params.warengruppe">
        <!-- <div class="box"> -->
        <!-- <h1>{{ listenbezeichnung }}</h1> -->
        <!--  </div> -->
        <!-- <div class="info-bar"> -->
        <div class="info-bar infobox">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt-auto col-padd">
                    <h2 class="listentitel">{{ listenbezeichnung }}</h2>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12 col-padd">
                    <div class="col-12 products-showing text-right d-none d-sm-block">
                        <strong>{{ anzahlartikel }}</strong> Artikel gefunden
                    </div>
                    <div class="col-12 products-number-sort">
                        <!--           <form
            class="form-inline d-block d-lg-flex justify-content-end flex-column flex-md-row"
          > -->
                        <form class="form-inline d-block justify-content-end">
                            <div class="products-sort-by mt-0 text-right">
                                <strong class="mr-1">Sortierung</strong>
                                <select name="sort-by" class="form-control ml-1" v-model="sort"
                                    @change="onChangeSort()">
                                    <option v-for="option in sortOptions" :value="option.value" :key="option.text">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <hr class="d-lg-block d-none" />
        </div>
        <div v-if="!ready" class="d-flex justify-content-center m-5">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <transition name="bounce">
            <div class="row products" v-if="ready">

                <div class="col-lg-4 col-md-6 col-sm-6 col-6 productbox" v-for="produkt in produkte" :key="produkt.id">
                    <ShopProduktlisteItem :produkt="produkt" :warengruppe="produkt.warengruppe"></ShopProduktlisteItem>
                </div>

            </div>
        </transition>
        <!-- PAGING HIER EINBAUEN -->
        <div class="pages">
            <nav aria-label="Page navigation example" class="d-flex justify-content-center">
                <ul class="pagination">
                    <li class="page-item" v-bind:class="{ disabled: aktiveseite === 1 }">
                        <span aria-label="Previous" class="page-link" @click="previousPage()"><span
                                aria-hidden="true">«</span><span class="sr-only">Previous</span></span>
                    </li>
                    <li class="page-item" v-for="pagingelement in pagingelements" :key="pagingelement.page"
                        v-bind:class="{ active: pagingelement.page === aktiveseite }"
                        @click="goToPage(pagingelement.page)">
                        <span class="page-link">{{ pagingelement.page }}</span>
                    </li>
                    <li class="page-item" v-bind:class="{ disabled: aktiveseite === anzahlseiten }">
                        <span aria-label="Next" class="page-link" @click="nextPage()"><span
                                aria-hidden="true">»</span><span class="sr-only">Next</span></span>
                    </li>
                </ul>
            </nav>
        </div>
        <div>
            <p>
                * Gilt für Lieferungen nach Deutschland. Lieferzeiten für andere Länder
                und Informationen zur Berechnung des Liefertermins siehe hier:
                Lieferzeiten. Werktage sind die Tage von Montag bis Freitag.
            </p>
        </div>
    </ProduktlisteLayout>
</template>

<script>
import ShopProduktlisteItem from "@/components/ShopProduktlisteItem.vue";
import ProduktlisteLayout from "@/layout/ProduktlisteLayout.vue";
import {
    getProducts
} from "@/api/produkt.api.js";
import { restoreScrollPosition, removeScrollPosition } from "@/js/scrollpositionhelper.js";

export default {
    name: 'ShopProduktlisteTyp',
    props: {
        listentyp: String
    },
    components: {
        ShopProduktlisteItem,
        ProduktlisteLayout
    },
    data: function () {
        return {
            ready: false,
            produkte: [],
            gezeigteSeitenMax: 5,
            anzahlseiten: 0,
            anzahlartikel: 0,
            artikelproseite: 12, /* Anzahl muss durch 6 teilbar sein */
            sort: "NAME",
            pagingelements: [{
                page: 1
            },
            {
                page: 2
            },
            {
                page: 3
            },
            {
                page: 4
            },
            {
                page: 5
            },
            ],
            filter: {
                warengruppe: "ALL",
                suchtext: "",
            },
            sortOptions: [{
                text: 'Name (aufsteigend)',
                value: 'NAME'
            },
            {
                text: 'Name (absteigend)',
                value: 'NAME_DESC'
            },
            {
                text: 'Hersteller (aufsteigend)',
                value: 'HERSTELLER'
            },
            {
                text: 'Hersteller (absteigend)',
                value: 'HERSTELLER_DESC'
            },
            {
                text: 'Preis (aufsteigend)',
                value: 'PREIS'
            },
            {
                text: 'Preis (absteigend)',
                value: 'PREIS_DESC'
            },
            ]
        };
    },
    computed: {
        listenbezeichnung() {
            if (this.listentyp === "WG") {
                return this.$store.getters["keyparameter/getWarengruppe"](
                    this.$route.params.warengruppe
                ).bezeichnung;
            }
            return "Suche: " + this.$route.params.suchtext;
        },
        aktiveseite() {
            return parseInt(this.$route.query.pg, 10);
        },
    },
    methods: {
        onChangeSort() {
            let navPoint = {
                query: {
                    pg: 1, pc: 0, st: this.sort, ac: 0
                }
            };
            this.navigateToNavPoint(navPoint);
        },
        computePaging() {
            // Berechne Anzahl dargestellte Seiten in der Paginganzeige
            let pagingSize = Math.min(this.gezeigteSeitenMax, this.anzahlseiten);
            // Berechne mittlere Position in der Paginganzeige
            let positionNormal = Math.ceil(pagingSize / 2);
            // Default: 1. Seite in der Paginganzeige
            let pageCounter = 1;
            if (this.aktiveseite > positionNormal) {
                // Wenn aktive Seite die mittlere Position in der Paginganzeige erreicht hat,
                // dann berechne die 1. Seite in der Paginganzeige:
                // Minimum von a: Aktive Seite - (mittlere Position - 1)
                //             b: Gesamte Anzahl Seiten - mittlere Position + 1
                pageCounter = Math.min(
                    this.aktiveseite - (positionNormal - 1),
                    this.anzahlseiten - pagingSize + 1
                );
            }
            // Dargestellte Seiten in der Paginganzeige neu aufbauen
            this.pagingelements = [];
            for (let i = 0; i < pagingSize; i++) {
                if (pageCounter <= this.anzahlseiten) {
                    this.pagingelements.push({
                        page: pageCounter
                    });
                }
                pageCounter++;
            }
        },
        nextPage() {
            this.navigateToPage(this.aktiveseite + 1);
        },
        previousPage() {
            this.navigateToPage(this.aktiveseite - 1);
        },
        goToPage(pagenumber) {
            this.navigateToPage(pagenumber);
        },
        navigateToPage(pagenumber) {
            let navPoint = {
                query: {
                    pg: pagenumber, pc: this.anzahlseiten, st: this.sort, ac: this.anzahlartikel
                }
            };
            this.navigateToNavPoint(navPoint);
        },
        navigateToNavPoint(navPoint) {
            if (this.listentyp === 'SU') {
                navPoint.name = 'ProduktlisteSuche';
                navPoint.params = { suchtext: this.$route.params.suchtext };
            } else {
                navPoint.name = 'ProduktlisteWarengruppe';
                navPoint.params = { warengruppe: this.$route.params.warengruppe };
            }
            let fullPath = this.$router.resolve(navPoint).route.fullPath;
            removeScrollPosition(fullPath);
            this.$router.push(navPoint);
        },
        loadProducts(countartikel) {
            console.log("loadProducts with countartikel=" + countartikel);
            this.ready = false;
            this.produkte = [];
            let paging = {
                aktiveseite: this.$route.query.pg,
                artikelproseite: this.artikelproseite,
                countartikel: countartikel,
            };
            let self = this;

            return new Promise((resolve) => {
                getProducts(this.filter, paging, this.sort)
                    .then(function (response) {
                        console.log("loadProducts SUCCESS");
                        self.produkte = response.data.produkte;
                        if (countartikel) {
                            self.anzahlseiten = response.data.anzahlseiten;
                            self.anzahlartikel = response.data.anzahlartikel;
                        }
                        self.computePaging();
                        self.ready = true;
                        resolve(true);
                    })
                    .catch(function (error) {
                        console.log(error);
                        resolve(false);
                    });
            });
        },
        isKeineProdukte() {
            return this.produkte.length === 0;
        },
        initPage() {
            if (this.listentyp === 'SU') {
                this.filter.warengruppe = "";
                this.filter.suchtext = this.$route.params.suchtext;
            } else {
                this.filter.warengruppe = this.$route.params.warengruppe;
                this.filter.suchtext = "";
            }
        }
    },
    async mounted() {
        this.initPage();
        this.anzahlartikel = parseInt(this.$route.query.ac, 10);
        this.anzahlseiten = parseInt(this.$route.query.pc, 10);
        this.sort = this.$route.query.st;
        // Artikel werden nur gezaehlt, wenn PageCount = 0 ist (weil Anzahl Seiten noch nicht bekannt)
        let countartikel = this.anzahlseiten == 0;
        await this.loadProducts(countartikel);
        restoreScrollPosition(this.$route.fullPath);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#content .product .text h3 {
    font-size: 0.95rem;
}

.infoloading {
    text-align: center;
    font-size: 1.125rem;
}

.box {
    padding: 10px;
}

.productbox {
    padding-right: 15px;
    padding-left: 15px;
}

.infobox {
    padding-bottom: 15px;
}

.listentitel {
    color: #2B90D9;
    margin-bottom: 0;
}

.products-showing,
.products-number-sort {
    padding: 0;
}

#content .info-bar .products-sort-by select {
    margin-left: 0;
}

@media only screen and (max-width: 767px) {
    .productbox {
        padding-right: 5px;
        padding-left: 5px;
    }

    .infobox {
        padding-bottom: 15px;
    }

    .col-padd {
        padding-right: 10px;
        padding-left: 10px;

    }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    from {
        transform: scale(0.5);
    }

    to {
        transform: scale(1.0);
    }
}
</style>
