<template>
<div class="login-page">
    <div class="container d-flex align-items-center position-relative py-5">
        <div class="card shadow-sm w-100 rounded overflow-hidden bg-none">
            <div class="card-body p-0">
                <div v-if="showSuccess" class="row gx-0">
                    <div class="col-lg-6">
                        <div class="info d-flex justify-content-center flex-column p-4">
                            <div class="py-1">
                                <h1>Passwort vergessen</h1>
                                <p>Bitte E-Mail-Postfach pr&uuml;fen.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 bg-white">
                        <div class="d-flex align-items-center px-4 px-lg-5 h-100">
                            <div class="p-4">
                                <p class="lead">Die E-Mail mit dem Link f&uuml;r die Zurücksetzung des Passworts ist unterwegs zu Ihnen!</p>
                                <p>
                                    Bitte &uuml;berpr&uuml;fen Sie Ihr E-Mail-Postfach und klicken Sie auf den in der E-Mail enthaltenen Link, um das Passwort zur&uuml;ckzusetzen.
                                </p>
                                <p>
                                    Falls die E-Mail nicht innerhalb der n&auml;chsten Minuten ankommt, &uuml;berpr&uuml;fen Sie bitte auch Ihren Spam-Ordner.
                                    Manchmal landen unsere Nachrichten dort versehentlich.
                                </p>
                                <p>
                                    Der Link für die Zurücksetzung des Passworts ist 15 Minuten lang gültig.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row gx-0 align-items-stretch">
                    <div class="col-lg-6">
                        <div class="info d-flex justify-content-center flex-column p-4">
                            <div class="py-1">
                                <h1>Passwort vergessen?</h1>
                                <p>Tragen Sie hier Ihre E-Mail-Adresse ein, um einen Link f&uuml;r die Zur&uuml;cksetzung des Passworts zu erhalten.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Form Panel    -->
                    <div class="col-lg-6 bg-white">
                        <div class="d-flex align-items-center px-4 px-lg-5 h-100">
                            <form class="login-form py-5 w-100 needs-validation" novalidate>
                                <div class="form-group mb-3">
                                    <label for="email">E-Mailadresse</label>
                                    <input class="form-control" id="email" type="text" name="email" v-model="benutzername" v-bind:class="{ 'is-invalid': checkInvalid('benutzername') }" autocomplete="email" required>
                                    <div class="invalid-feedback">
                                        {{ this.getInvalidMessage("benutzername") }}
                                    </div>
                                </div>
                                <button class="btn btn-primary mb-3" id="login" type="button" @click="resetpw()">Passwort zur&uuml;cksetzen</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    serviceRequestReset
} from "@/api/session.api.js";
export default {
    name: 'ShopPasswortVergessen',
    data: function () {
        return {
            benutzername: "",
            feedback: {},
            showSuccess: false,
        };
    },
    methods: {
        async resetpw() {
            const response = await serviceRequestReset(this.benutzername.trim());
            if (response.data.successful) {
                this.showSuccess = true;
            } else {
                this.feedback = response.data.feedback;
            }
        },
        checkInvalid(feldname) {
            if (Object.prototype.hasOwnProperty.call(this.feedback, feldname)) {
                return true;
            }
            return false;
        },
        getInvalidMessage(feldname) {
            if (Object.prototype.hasOwnProperty.call(this.feedback, feldname)) {
                return this.feedback[feldname];
            }
            return "";
        },
    },
}
</script>
