<template>
<div class="login-page">
    <div class="container d-flex align-items-center position-relative py-5">
        <div class="card shadow-sm w-100 rounded overflow-hidden bg-none">
            <div class="card-body p-0">
                <div class="row gx-0 align-items-stretch">
                    <!-- Logo & Information Panel-->
                    <div class="col-lg-6">
                        <div class="info d-flex justify-content-center flex-column p-4">
                            <div class="py-1">
                                <h1>Anmeldung</h1>
                                <p>Wenn Sie bereits registriert sind, dann melden Sie sich bitte mit Ihrer E-Mailadresse als Benutzernamen an.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Form Panel    -->
                    <div class="col-lg-6 bg-white">
                        <div class="d-flex align-items-center px-4 px-lg-5 h-100">
                            <form class="login-form py-5 w-100">
                                <div class="form-group mb-3">
                                    <label for="email">E-Mailadresse</label>
                                    <input class="form-control" id="email" type="text" v-model="anmeldung.benutzername" required name="username" autocomplete="username">
                                </div>
                                <div class="form-group mb-4">
                                    <label for="password">Passwort</label>
                                    <input class="form-control" id="password" type="password" v-model="anmeldung.passwort" v-on:keyup.enter="anmelden()" required name="password" autocomplete="current-password">
                                </div>
                                <div v-if="isMessageVorhanden" class="form-group text-danger">
                                    {{ anmeldung.message }}
                                </div>
                                <button class="btn btn-primary mb-3" id="login" type="button" @click="anmelden()">Anmelden</button><br><router-link class="text-sm text-paleBlue" :to="{ name: 'PasswortVergessen'}">Passwort vergessen?</router-link><br><small class="text-gray-500">Noch nicht registriert? </small><router-link :to="{ name: 'Registrierung'}" class="text-sm text-paleBlue" >Jetzt registrieren</router-link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { serviceAnmelden } from "@/api/session.api.js";
export default {
    name: 'ShopLogin',
    data: function () {
        return {
            anmeldung: {
                benutzername: "",
                passwort: "",
                message: "",
            },
        };
    },
    computed: {
        isMessageVorhanden() {
            return this.anmeldung.message != "";
        },
        herkunft() {
            return this.$route.params.herkunft;
        },
    },
    methods: {
        async anmelden() {
            try {
                const response = await serviceAnmelden(
                    this.anmeldung.benutzername,
                    this.anmeldung.passwort,
                    this.$store.getters.getWarenkorbHash
                );
                console.log(
                    "anmelden SUCCESS: response=" + JSON.stringify(response.data)
                );
                if (response.data.successful) {
                    // Anmeldung erfolgreich
                    this.$store.dispatch("anmelden", {
                        jwt: response.data.jwt,
                        warenkorbhash: response.data.warenkorbhash,
                        benutzername: response.data.benutzername,
                    });
                    console.log("self.loginHerkunft=" + this.loginHerkunft);
                    if (this.loginHerkunft === "Bestellung") {
                        this.$emit("anmeldung-erfolgreich", response.data.warenkorbchanged);
                    } else {
                        // PUSH Router
                        console.log("login router push: herkunft=" + this.herkunft);
                        let pushdestination = typeof this.herkunft !== 'undefined' ? this.herkunft : "Home";
                        if (this.$route.params.warengruppe != null) {
                            this.$router.push({
                                name: pushdestination,
                                params: {
                                    warengruppe: this.$route.params.warengruppe,
                                },
                                query: {
                                    pg: 1, pc: 0, st: "NAME"
                                }
                            });
                        } else if (pushdestination === "Produktdetail") {
                            this.$router.push({
                                name: pushdestination,
                                params: {
                                    artikelid: this.$route.params.artikelid
                                },
                            });
                        } else {
                            if (
                                pushdestination === "Login" ||
                                pushdestination === "Logout" ||
                                pushdestination === "RegistrierungAktivieren" ||
                                typeof pushdestination === "undefined"
                            ) {
                                console.log(
                                    "Login: Push to Home  currentPushDestination=" +
                                    pushdestination
                                );
                                pushdestination = "Home";
                            }
                            this.$router.push({
                                name: pushdestination
                            });
                        }
                    }
                } else {
                    this.anmeldung.message =
                        "Kombination aus Benutzername/Passwort existiert nicht.";
                }
            } catch (error) {
                console.log("Error ShopLogin: " + error);
            }
        },
    },
}
</script>
