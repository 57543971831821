import httpClient from '@/api/httpClient.js'

const END_POINT = './api/bestellungaktion.php';

const serviceLoadKundendaten = (warenkorbhash, kundenhash) => httpClient.post(END_POINT, { aktion: "LOAD-KUNDENDATEN", warenkorbhash, kundenhash });

const serviceLoadKomplett = (kundenhash) => httpClient.post(END_POINT, { aktion: "LOAD-KOMPLETT", kundenhash });

const serviceLoadKundenhash = (warenkorbhash) => httpClient.post(END_POINT, { aktion: "LOAD-KUNDENHASH", warenkorbhash });

const serviceSaveKundendaten = (rg, rg_gleich_lfg, lfg, warenkorbhash, kundenhash) => httpClient.post(END_POINT, { aktion: "SAVE-KUNDENDATEN", rg, rg_gleich_lfg, lfg, warenkorbhash, kundenhash });

const serviceSaveVersandart = (versandartid, warenkorbhash, kundenhash) => httpClient.post(END_POINT, { aktion: "SAVE-VERSANDART", versandartid, warenkorbhash, kundenhash });

const serviceLoadBestellungZahlungsart = (warenkorbhash, kundenhash) => httpClient.post(END_POINT, { aktion: "LOAD-BESTELLUNG-ZAHLUNGSART", warenkorbhash, kundenhash });

const serviceSaveZahlungsart = (zahlungsartid, warenkorbhash, kundenhash) => httpClient.post(END_POINT, { aktion: "SAVE-ZAHLUNGSART", zahlungsartid, warenkorbhash, kundenhash });

const serviceLoadBestellung = (warenkorbhash, kundenhash) => httpClient.post(END_POINT, { aktion: "LOAD-BESTELLUNG", warenkorbhash, kundenhash});

const serviceBestellungAufgeben = (zustimmung, warenkorbhash, kundenhash) => httpClient.post(END_POINT, { aktion: "BESTELLUNG-AUFGEBEN", zustimmung, warenkorbhash, kundenhash});



export {
    serviceLoadKundendaten,
    serviceSaveKundendaten,
    serviceSaveVersandart,
    serviceLoadBestellungZahlungsart,
    serviceSaveZahlungsart,
    serviceLoadBestellung,
    serviceBestellungAufgeben,
    serviceLoadKundenhash,
    serviceLoadKomplett
}