<template>
<div class="shopfooter">
    <!--
    *** FOOTER ***
    _________________________________________________________
    -->
    <div id="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <h4 class="mb-3">Über uns</h4>
                    <ul class="list-unstyled">
                        <li>
                            <router-link to="/about/impressum">Impressum</router-link>
                        </li>
                        <li>
                            <router-link to="/about/agb">AGB</router-link>
                        </li>
                        <li>
                            <router-link to="/about/widerruf">Widerrufsbelehrung</router-link>
                        </li>
                        <li>
                            <router-link to="/about/datenschutz">Datenschutzerklärung</router-link>
                        </li>
                        <li>
                            <router-link to="/about/zahlungsarten">Zahlungsarten</router-link>
                        </li>
                        <li>
                            <router-link to="/about/versandinformationen">Versandinformationen</router-link>
                        </li>
                    </ul>
                </div>
                <!-- /.col-lg-3-->
                <div class="col-lg-3 col-md-6">
                    <h4 class="mb-3">Kundenbereich</h4>
                    <ul class="list-unstyled">
                        <li>
                            <router-link to="/login">Login</router-link>
                        </li>
                        <li>
                            <router-link to="/register/start">Registrierung</router-link>
                        </li>
                    </ul>
                </div>
                <!-- /.col-lg-3-->
                <div class="col-lg-3 col-md-6">
                    <h4 class="mb-3">Geschäftsadresse</h4>
                    <p><strong>Ranu Hasuk</strong><br>Bockrisweg 11<br>22761 Hamburg<br>Deutschland</p>
                    <router-link to="/kontakt">Kontakt</router-link>
                    <hr class="d-block d-md-none">
                </div>
                <!-- /.col-lg-3-->
                <div class="col-lg-3 col-md-6">
                    <h4 class="mb-3">Bleiben Sie mit uns in Verbindung</h4>
                    <p class="social"><a href="#" class="facebook external"><i class="fa fa-facebook"></i></a><a href="#" class="twitter external"><i class="fa fa-twitter"></i></a><a href="#" class="instagram external"><i class="fa fa-instagram"></i></a><a href="#" class="gplus external"><i class="fa fa-google-plus"></i></a><a href="#" class="email external"><i class="fa fa-envelope"></i></a></p>
                </div>
                <!-- /.col-lg-3-->
            </div>
            <!-- /.row-->
        </div>
        <!-- /.container-->
    </div>
    <!-- /#footer-->
    <!-- *** FOOTER END ***-->


    <!--
    *** COPYRIGHT ***
    _________________________________________________________
    -->
    <div id="copyright">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-2 mb-lg-0">
                    <p class="text-center text-lg-left">©2024 Ranu Hasuk</p>
                </div>
                <div class="col-lg-6">
                </div>
            </div>
        </div>
    </div>
    <!-- *** COPYRIGHT END ***-->
</div>
</template>
<script>
    export default {
        name: 'ShopFooter',
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #footer {
        font-size: 0.7rem;
        padding: 25px;
    }
    #footer h4 {
        font-size: 0.9rem;
    }
/*     .shopfooter {
        margin-top: 30px;
    } */
</style>
