<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <AdressBox titel="Rechnungsadresse" :adresse="this.rechnungsadresse"
          :validierung="this.rechnungsadressevalidierung" checkboxtext="Rechnungsadresse entspricht Lieferadresse"
          :checkboxvalue="rechnunggleichliefer" v-on:checkbox-changed="rechnungsadresseCheckboxChanged"
          :has_email="true" />
      </div>
      <div class="col-lg-12">
        <AdressBox titel="Lieferadresse" :adresse="this.lieferadresse" :validierung="this.lieferadressevalidierung"
          :has_email="false" v-if="rechnunggleichliefer === false" />
      </div>
    </div>
    <div class="box-footer d-flex justify-content-between flex-lg-row">
      <div class="left">
        <button type="button" class="btn btn-outline-secondary" @click="navigationZurueck()">
          <i class="fa fa-chevron-left"></i> Zur&uuml;ck</button>
      </div>
      <div class="right">
        <button type="button" class="btn btn-primary" @click="weiterAnschrift()">
          Weiter <i class="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import AdressBox from "@/components/AdressBox.vue";
import { serviceLoadKundendaten, serviceSaveKundendaten } from "@/api/bestellung.api.js";

export default {
  name: "ShopCheckoutAdresse",
  components: { AdressBox },
  methods: {
    navigationZurueck() {
      if (this.$store.getters.isAngemeldet) {
        this.$router.push({ name: "Warenkorb" });
      } else {
        this.$router.push({ name: "BestellungAnmeldung" });
      }
    },
    rechnungsadresseCheckboxChanged(newValue) {
      this.rechnunggleichliefer = newValue;
    },
    async weiterAnschrift() {
      try {
        const response = await serviceSaveKundendaten(this.rechnungsadresse, this.rechnunggleichliefer,
          this.lieferadresse, this.$store.getters.getWarenkorbHash, this.$store.getters.getKundenHash);
        this.rechnungsadressevalidierung = response.data.validierung.rg;
        this.lieferadressevalidierung = response.data.validierung.lfg;
        this.$store.commit("setKundenHash", response.data.bestellung.kundendaten.kundenhash);
        if (response.data.successful) {
          // Lieferadresse vom Server übernehmen (weil sie ggf. geändert wurde)
          this.lieferadresse = response.data.bestellung.kundendaten.lfg;
          // Adressdaten im zentralen Store speichern
          this.$store.commit("bestellung/setAdressdaten", {
            rechnungsadresse: this.rechnungsadresse, lieferadresse: this.lieferadresse,
            rechnunggleichliefer: this.rechnunggleichliefer
          });
          this.$router.push({ name: "BestellungLiefermethode" });
        }
      } catch (error) {
        console.log("ShopCheckoutAdresse weiterAnschrift ERROR: " + error);
      }
    },
  },
  created() {
    console.log("ShopCheckoutAdresse created");
  },
  mounted() {
    this.$store.dispatch("providingHashvalues").then(async r => {
      if (r) {
        serviceLoadKundendaten(this.$store.getters.getWarenkorbHash, this.$store.getters.getKundenHash).then(response => {
          if (response.data.successful) {
            console.log("ShopCheckoutAdresse mounted after serviceLoadKundendaten");
            this.rechnungsadresse = response.data.bestellung.kundendaten.rg;
            this.lieferadresse = response.data.bestellung.kundendaten.lfg;
            console.log("self.rechnunggleichliefer = " + response.data.bestellung.kundendaten.rg_gleich_lfg);
            this.rechnunggleichliefer = response.data.bestellung.kundendaten.rg_gleich_lfg;
            this.$store.commit("bestellung/setAdressdaten", {
              rechnungsadresse: this.rechnungsadresse, lieferadresse: this.lieferadresse,
              rechnunggleichliefer: this.rechnunggleichliefer
            });
          }
        })
      } else {
        console.log("Kein Kundenhash vorhanden! Daten sind weg!");
      }
    });
  },
  data: function () {
    return {
      rechnunggleichliefer: true,
      rechnungsadresse: {
        anrede: null,
        name: "",
        vorname: "",
        adresszusatz: "",
        strasse: "",
        plz: "",
        ort: "",
        land: "DEU",
        telefon: "",
        email: ""
      },
      rechnungsadressevalidierung: {
        anrede: { valid: true, feedback: "" },
        name: { valid: true, feedback: "" },
        vorname: { valid: true, feedback: "" },
        adresszusatz: { valid: true, feedback: "" },
        strasse: { valid: true, feedback: "" },
        plz: { valid: true, feedback: "" },
        ort: { valid: true, feedback: "" },
        land: { valid: true, feedback: "" },
        telefon: { valid: true, feedback: "" },
        email: { valid: true, feedback: "" }
      },
      lieferadresse: {
        anrede: null,
        name: "",
        vorname: "",
        adresszusatz: "",
        strasse: "",
        plz: "",
        ort: "",
        land: "DEU",
        telefon: "",
      },
      lieferadressevalidierung: {
        anrede: { valid: true, feedback: "" },
        name: { valid: true, feedback: "" },
        vorname: { valid: true, feedback: "" },
        adresszusatz: { valid: true, feedback: "" },
        strasse: { valid: true, feedback: "" },
        plz: { valid: true, feedback: "" },
        ort: { valid: true, feedback: "" },
        land: { valid: true, feedback: "" },
        telefon: { valid: true, feedback: "" },
      },
    };
  },
};
</script>
<style scoped>
.nav-link {
  padding: 0;
}

.clickicon {
  cursor: pointer;
}

.warenkorb-summe {
  padding: 3px 12px;
}

.warenkorb-versandkosten {
  border-top: 0px solid white;
  padding: 3px 12px;
}

.warenkorb-gesamtpreis {
  border-top: 1px solid #dee2e6;
  border-bottom: 5px double #dee2e6;
  padding: 3px 12px;
}
</style>