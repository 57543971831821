<template>
<div class="login-page">
    <div class="container d-flex align-items-center position-relative py-5">
        <div class="card shadow-sm w-100 rounded overflow-hidden bg-none">
            <div class="card-body p-0">
                <div class="row gx-0">
                    <div class="col-lg-6">
                        <div class="info d-flex justify-content-center flex-column p-4">
                            <div v-if="aktivierungErfolgreich" class="py-1">
                                <h1>Registrierung abgeschlossen</h1>
                                <p>Ihr Kundenkonto wurde erstellt.</p>
                            </div>
                            <div v-if="!aktivierungErfolgreich" class="py-1">
                                <h1>Registrierung fehlerhaft</h1>
                                <p>Es gibt leider ein Problem.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 bg-white">
                        <div class="d-flex align-items-center px-4 px-lg-5 h-100">
                            <div class="p-4">
                                <div v-if="aktivierungErfolgreich">
                                    <p class="lead">Herzlich willkommen!</p>
                                    <p>
                                        Sie haben erfolgreich die Registrierung abgeschlossen. Wir freuen uns sehr, Sie als Kunde begr&uuml;&szlig;en zu d&uuml;rfen.
                                    </p>
                                    <p>
                                        Falls Sie Fragen haben oder Unterst&uuml;tzung ben&ouml;tigen, steht Ihnen unser Kundenservice zur Verf&uuml;gung. Wir w&uuml;nschen Ihnen ein angenehmes Einkaufserlebnis
                                        und hoffen, dass Sie viele gro&szlig;artige Produkte bei uns finden.
                                    </p>
                                    <p>Viel Spa&szlig; beim Shoppen!</p>
                                </div>
                                <div v-if="!aktivierungErfolgreich">
                                    <p class="lead">Fehler bei der Registrierungsaktivierung</p>
                                    <p>Es tut uns leid, aber die Aktivierung Ihrer Registrierung war nicht erfolgreich. Der Link zur Aktivierung ist entweder ung&uuml;ltig oder abgelaufen.</p>
                                    <p>Bitte &uuml;berpr&uuml;fen Sie, ob Sie den neuesten Link aus der Registrierungsmail verwendet haben. Falls die Aktivierung weiterhin nicht funktioniert, registrieren Sie sich bitte erneut.</p>
                                    <p>Sollten Sie weiterhin Probleme haben oder Unterst&uuml;tzung ben&ouml;tigen, kontaktieren Sie uns bitte per E-Mail.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    activateBenutzer
} from '../api/register.api';
export default {
    name: "ShopRegistrierungAktivieren",
    data: function () {
        return {
            aktivierungErfolgreich: false,
            aktivierungMessage: ""
        };
    },
    methods: {
        aktivieren() {
            let self = this;
            activateBenutzer(this.$route.query.activationcode).then(
                function (response) {
                    self.aktivierungErfolgreich = response.data.successful;
                    self.aktivierungMessage = response.data.message;
                }
            )
        },
    },
    created() {
        this.aktivieren();
    },
};
</script>