import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ShopLogin from '../views/ShopLogin.vue'
import ShopRegistrierung from '../views/ShopRegistrierung.vue'
import ShopKontakt from '../views/ShopKontakt.vue'
import ShopProduktlisteWarengruppe from '../views/ShopProduktlisteWarengruppe.vue'
import ShopProduktlisteSuche from '../views/ShopProduktlisteSuche.vue'
import ShopWarenkorb from '../views/ShopWarenkorb.vue'
import ShopProduktdetail from '../components/ShopProduktdetail.vue'
import ShopRegistrierungAktivieren from '../views/ShopRegistrierungAktivieren.vue'
import ShopCheckout from '../views/bestellung/ShopCheckout.vue'
import ShopCheckoutAnmeldung from '../views/bestellung/ShopCheckoutAnmeldung.vue'
import ShopCheckoutAdresse from '../views/bestellung/ShopCheckoutAdresse.vue'
import ShopPasswortVergessen from '../views/ShopPasswortVergessen.vue'
import ShopPasswortAendern from '../views/ShopPasswortAendern.vue'
import { saveScrollPosition, getScrollPosition } from '../js/scrollpositionhelper.js'

Vue.use(VueRouter)

const scrollPositionPages = ["Home", "ProduktlisteWarengruppe", "ProduktlisteSuche"];

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/produkte/:warengruppe/:artikelid',
        name: 'Produktdetail',
        component: ShopProduktdetail
    },    
    {
        path: '/produkte/:warengruppe',
        name: 'ProduktlisteWarengruppe',
        component: ShopProduktlisteWarengruppe,
    },
    {
        path: '/produktsuche/:suchtext',
        name: 'ProduktlisteSuche',
        component: ShopProduktlisteSuche,
    },
    {
        path: '/login',
        name: 'Login',
        component: ShopLogin
    },
    {
        path: '/register',
        name: 'Registrierung',
        component: ShopRegistrierung,
    },
    {
        path: '/register/aktivieren',
        name: 'RegistrierungAktivieren',
        component: ShopRegistrierungAktivieren,
    },
    {
        path: '/forget',
        name: 'PasswortVergessen',
        component: ShopPasswortVergessen,
    },
    {
        path: '/forget/change',
        name: 'PasswortAendern',
        component: ShopPasswortAendern,
    },
    {
        path: '/checkout',
        name: 'Bestellung',
        component: ShopCheckout,
        children: [
         //   { path: '/checkout/anmeldung', name: 'BestellungAnmeldung', component: () => import('../views/bestellung/ShopCheckoutAnmeldung.vue') },
         //   { path: '/checkout/adresse', name: 'BestellungAdresse', component: () => import('../views/bestellung/ShopCheckoutAdresse.vue') },
            { path: '/checkout/anmeldung', name: 'BestellungAnmeldung', component: ShopCheckoutAnmeldung },
            { path: '/checkout/adresse', name: 'BestellungAdresse', component: ShopCheckoutAdresse },
            { path: '/checkout/liefermethode', name: 'BestellungLiefermethode', component: () => import('../views/bestellung/ShopCheckoutLiefermethode.vue') },
            { path: '/checkout/zahlmethode', name: 'BestellungZahlmethode', component: () => import('../views/bestellung/ShopCheckoutZahlmethode.vue') },
            { path: '/checkout/uebersicht', name: 'BestellungUebersicht', component: () => import('../views/bestellung/ShopCheckoutUebersicht.vue') },
        ]
    },
    { path: '/checkoutfinished',
      name: 'BestellungAbgeschlossen',
      component: () => import('../views/bestellung/ShopCheckoutAbgeschlossen.vue')
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: ShopKontakt
    },
    {
        path: '/warenkorb',
        name: 'Warenkorb',
        component: ShopWarenkorb

    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import( /* webpackChunkName: "about" */ '../views/rechtlich/ShopAbout.vue'),
        children: [
            {
                path: '/about/impressum',
                name: 'Impressum',
                component: () =>
                    import( /* webpackChunkName: "about" */ '../views/rechtlich/ShopAboutImpressum.vue')
            },
            {
                path: '/about/agb',
                name: 'AGB',
                component: () =>
                    import( /* webpackChunkName: "about" */ '../views/rechtlich/ShopAboutAGB.vue')
            },
            {
                path: '/about/widerruf',
                name: 'Widerrufsbelehrung',
                component: () =>
                    import( /* webpackChunkName: "about" */ '../views/rechtlich/ShopAboutWiderruf.vue')
            },
            {
                path: '/about/datenschutz',
                name: 'Datenschutz',
                component: () =>
                    import( /* webpackChunkName: "about" */ '../views/rechtlich/ShopAboutDatenschutz.vue')
            },
            {
                path: '/about/zahlungsarten',
                name: 'Zahlungsarten',
                component: () =>
                    import( /* webpackChunkName: "about" */ '../views/rechtlich/ShopAboutZahlungsarten.vue')
            },
            {
                path: '/about/versandinformationen',
                name: 'Versandinformationen',
                component: () =>
                    import( /* webpackChunkName: "about" */ '../views/rechtlich/ShopAboutVersandkosten.vue')
            }
        ]
    },
    {
        path: '*',
        component: () =>
            import( /* webpackChunkName: "about" */ '../views/Shop404.vue')
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior(to) {
        console.log("VueRouter scrollBehavior to=" + to.name + " warengruppe=" + to.params.warengruppe + " suchtext=" + to.params.suchtext);
        if (scrollPositionPages.includes(to.name)) {
            console.log("VueRouter scrollBehavior return saved scrollposition");
            return getScrollPosition(to.fullPath);
        }
        console.log("VueRouter scrollBehavior return intial scrollposition");
        return {x: 0, y: 0}
    }
})

router.beforeEach((to, from, next) => {
    if (from.name && scrollPositionPages.includes(from.name) /* && to.name != from.name */) {
      console.log("save scrollPositions for " + from.name);
      saveScrollPosition(from.fullPath);
      console.log("beforeEach: fullPath=" + from.fullPath);
    }
    next();
  });
  
export default router
