<template>
  <div id="all">
    <div id="content">
      <!--         <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div id="main-slider">
                <Carousel :items="1" :nav="false" :autoplay="true" :rewind="false" :loop="true">
                <img src="../assets/img/slider/Nesvita_Werbung.png" class="img-fluid">    
                <img src="../assets/img/slider/eurocake_pandan.png" class="img-fluid">
                <img src="../assets/img/slider/eurocake_banana.png" class="img-fluid">
                </Carousel>
              </div>
            </div>
          </div>
        </div> -->
      <!--
        *** ADVANTAGES HOMEPAGE ***
        _________________________________________________________
        -->
      <!--         <div id="advantages">
          <div class="container">
            <div class="row mb-4">
              <div class="col-md-4">
                <div class="box clickable d-flex flex-column justify-content-center mb-0 h-100">
                  <div class="icon"><i class="fa fa-truck"></i></div>
                  <h3><a href="#">Schnelle Lieferung</a></h3>
                  <p class="mb-0">Die bestellten Waren werden schnell und zuverlässig über DHL ausgeliefert.</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="box clickable d-flex flex-column justify-content-center mb-0 h-100">
                  <div class="icon"><i class="fa fa-shopping-cart"></i></div>
                  <h3><a href="#">Produkte aus Thailand</a></h3>
                  <p class="mb-0">Wir haben viele, beliebte Produkte aus Thailand in unserem Sortiment.</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="box clickable d-flex flex-column justify-content-center mb-0 h-100">
                  <div class="icon"><i class="fa fa-thumbs-up"></i></div>
                  <h3><a href="#">Zufriedene Kunden</a></h3>
                  <p class="mb-0">Wir legen großen Wert auf die Zufriedenheit unserer Kunden.</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- /#advantages-->
      <!-- *** ADVANTAGES END ***-->

      <!-- *** WARENGRUPPEN *** -->
      <div class="container">
        <div class="row">
          <div v-for="warengruppe in warengruppen" :key="warengruppe.name" class="col-md-6 col-xl-6 col-sm-6 col-xs-12">

            <div class="box clickable justify-content-center mt-2 text-center">
              <!--<h2><router-link :to="{ name: 'ProduktlisteWarengruppe' , params: { warengruppe: warengruppe.name }}">{{warengruppe.bezeichnung}}</router-link></h2>-->
              <h2><a href="#" @click.prevent="routeToWarengruppe(warengruppe.name)">{{ warengruppe.bezeichnung }}</a>
              </h2>
              <!-- <p class="lead">Hier kommt noch eine Beschreibung</p> -->
              <!-- <div id="get-inspired" class="owl-carousel owl-theme"> -->
              <!-- <router-link :to="{ name: 'ProduktlisteWarengruppe' , params: { warengruppe: warengruppe.name }}"><img :src="getImgUrl(warengruppe.name)" class="img-fluid"></router-link> -->
              <a href="#" @click.prevent="routeToWarengruppe(warengruppe.name)"><img :src="getImgUrl(warengruppe.name)"
                  class="img-fluid"></a>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- *** WARENGRUPPEN END *** -->

    </div>
  </div>

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Carousel from "vue-owl-carousel2";
import { restoreScrollPosition, removeScrollPosition } from '@/js/scrollpositionhelper.js'

export default {
  name: "Home",
  // components: { Carousel },
  computed: {
    warengruppen() {
      return this.$store.getters["keyparameter/getWarengruppen"];
    },
  },
  methods: {
    grouppicturename(groupname) {
      console.log("grouppicture: " + groupname);
      console.log("../assets/img/groups/".concat(groupname, "_1020x600.png"));
      return "../assets/img/groups/".concat(groupname, "_1020x600.png");
    },
    getImgUrl(groupname) {
      var images = require.context('../assets/img/groups', false, /\.webp$/)
      return images('./' + groupname + "_1020x600.webp")
    },
    routeToWarengruppe(warengruppe) {
      let navPoint = {
                name: 'ProduktlisteWarengruppe',
                params: {
                    warengruppe: warengruppe
                },
                query: {
                    pg: 1, pc: 0, st: "NAME"
                }
            };
      this.navigateToProduktliste(navPoint);
    },
    navigateToProduktliste(navPoint) {
      // Check to avoid NavigationDuplicated-Error
      let newRoute = this.$router.resolve(navPoint).route.fullPath;
      let currentRoute = this.$route.fullPath;
      if (newRoute !== currentRoute) {
        // Entferne ggf. vorhandene Scrollposition, da bei Ansprung auf xy 0,0 gesprungen werden soll
        removeScrollPosition(newRoute);
        // Führe die Navigation aus
        this.$router.push(navPoint);
      }
    },
  },
  created() {
    console.log("Home created");
    restoreScrollPosition(this.$route.fullPath);
    console.log("Home sessionStorage keys=" + Object.keys(sessionStorage));
  },

};
</script>
