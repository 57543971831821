<template>
  <div class="product">
    <router-link
      :to="{
        name: 'Produktdetail',
        params: { warengruppe: warengruppe, artikelid: produkt.id },
      }"
      ><img :src="getProduktListImageWithDefault(produkt.img)" alt="" class="img-fluid" @error="setImageError()"
    /></router-link>
    <div class="text">
      <div class="produktname">
      <h3>
        <router-link
          :to="{
            name: 'Produktdetail',
            params: { warengruppe: warengruppe, artikelid: produkt.id },
          }"
          >{{ produkt.name }}</router-link
        >
      </h3>
      </div>
      <h4 class="hersteller">{{ produkt.hersteller }}</h4>
      <p class="price text-center"><del></del>{{ produkt.bruttopreis | betrag }} &euro;</p>

      <p id="mwst" class="text-center">
        inkl. {{ produkt.umsatzsteuersatz }}% MwSt. zzgl.
        <router-link to="/about/versandinformationen">Versandkosten</router-link>
      </p>
      <p class="vergleich text-center">
        Inhalt:&nbsp;{{ produkt.nettoinhalt | gewicht_oder_volumen }}{{ nettoinhalteinheit }}&nbsp;<span class="text-nowrap">({{ vergleichspreis | betrag }} &euro; / {{ vergleichseinheit }})</span>
      </p>
      <p class="text-center"><i class="fa fa-truck mr-1" :class="lieferzeitfarbe()"></i>{{lieferzeit}}</p>
      <p class="buttons">
        <router-link
          :to="{
            name: 'Produktdetail',
            params: { warengruppe: warengruppe, artikelid: produkt.id },
          }"
          class="btn btn-outline-secondary"
        >
          Produktdetails
        </router-link>
        <button type="button" class="btn btn-primary" @click="addToWarenkorb" :disabled="!isLieferbar()">
          <i class="fa fa-shopping-cart"></i>In den Warenkorb
        </button>
      </p>
    </div>
    <!-- /.text-->
    <div class="ribbon sale" v-if="isSale">
      <div class="theribbon">SALE</div>
      <div class="ribbon-background"></div>
    </div>
    <div class="ribbon new" v-if="isNeu">
      <div class="theribbon">NEU</div>
      <div class="ribbon-background"></div>
    </div>
  </div>
  <!-- /.product            -->
</template>
<script>
import { produktmethods } from "@/mixins/produkt_methods.js";
import { standardfilters } from "@/mixins/standard_filters.js";
import { warenkorballgemein } from "@/mixins/warenkorb_allgemein.js";

export default {
  name: "ShopProduktlisteItem",
  props: { produkt: Object, warengruppe: String } ,
  mixins: [produktmethods, standardfilters, warenkorballgemein],
  /*data: function () {
    return {
      imageerror: false,
    };
  },*/
  /*methods: {
    getProduktImageWithDefault(produktid) {
      if (this.imageerror) {
        return this.getDefaultProduktImage();
      }
      return this.getProduktImage(produktid);
    },
    setImageError() {
      this.imageerror = true;
    }
  }*/
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#content .product .text h3 {
  font-size: 1.1rem;
  height: 1.3rem;
}
#content .product .text p.price {
  font-size: 1.25rem;
  font-weight: 600;
}
.vergleich {
  text-align: center;
}
p {
  margin-bottom: 0.25rem;
}
.product .nettoinhalt {
  text-align: center;
}

h4.hersteller {
  font-size: 0.75rem;
}

h3 a {
  font-size: 14px;
  overflow: visible;
}

.produktname {
  min-height: 70px;
  display: block;
}
</style>