import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import { getAnmeldedaten } from "@/store/data.js";

const httpClient = axios.create({
    //baseURL: ''
    headers: {
        "Content-Type": "application/json",
    }
});

// interceptor to catch errors
const errorInterceptor = error => {
    // check if it's a server error
    if (!error.response) {
      console.log('Network/Server error');
      return Promise.reject(error);
    }

    // all the other error responses
    switch(error.response.status) {
        case 401:
            store.dispatch("abmelden");
            router.push({ name: 'Login' });
            break;
        default:
             console.log("httpClient.js ERROR: " + error);
             var msg = "Oops! Da ist etwas schiefgelaufen ...<BR>";
             if ('data' in error.response && 'errordata' in error.response.data) {
                var errordata = error.response.data.errordata;
                msg = msg + "Fehlermeldung: " + errordata.message + "<BR>" + "Fehler in Datei: " + errordata.file + "<BR>" +
                "Fehler in Zeile: " + errordata.line + "<BR>" + errordata.trace;
             }
              Vue.$toast.open({
                message: msg,
                type: 'error',
                duration: 30000,
                // all of other options may go here
            });

    }
    return Promise.reject(error);
}

// Interceptor for responses
const responseInterceptor = response => {
    switch(response.status) {
        case 200: 
            // yay!
            break;
        // any other cases
        default:
            // default case
    }

    return response;
}

const authInterceptor = (config) => {
    config.headers['Authorization'] = "Bearer " + getAnmeldedaten();
    return config;
}

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);


export default httpClient;