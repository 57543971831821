var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"shopheadertop"}},[_c('div',{staticClass:"header",attrs:{"id":"top"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6 text-center text-lg-right"},[_c('ul',{staticClass:"menu list-inline mb-0"},[(_vm.isAbgemeldet)?_c('li',{staticClass:"list-inline-item"},[_c('router-link',{attrs:{"to":{
                  name: 'Login',
                  params: {
                    herkunft: _vm.$route.name,
                    warengruppe: _vm.$route.params.warengruppe,
                    artikelid: _vm.$route.params.artikelid,
                  },
                }}},[_vm._v("Anmelden")])],1):_vm._e(),(_vm.isAbgemeldet)?_c('li',{staticClass:"list-inline-item"},[_c('router-link',{attrs:{"to":"/register"}},[_vm._v("Registrierung")])],1):_vm._e(),(_vm.isAngemeldet)?_c('li',{staticClass:"list-inline-item"},[_c('span',{staticClass:"displaybenutzername"},[_vm._v(_vm._s(_vm.benutzername))])]):_vm._e(),(_vm.isAngemeldet)?_c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.abmelden()}}},[_vm._v("Abmelden")])]):_vm._e(),_c('li',{staticClass:"list-inline-item"},[_c('router-link',{attrs:{"to":"/kontakt"}},[_vm._v("Kontakt")])],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 offer mb-3 mb-lg-0"},[_c('a',{staticClass:"btn btn-danger btn-sm",attrs:{"href":"#"}},[_vm._v("Dieser Onlineshop befindet sich im Probebetrieb - es sind noch keine Bestellungen möglich!")])])}]

export { render, staticRenderFns }