var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product"},[_c('router-link',{attrs:{"to":{
      name: 'Produktdetail',
      params: { warengruppe: _vm.warengruppe, artikelid: _vm.produkt.id },
    }}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getProduktListImageWithDefault(_vm.produkt.img),"alt":""},on:{"error":function($event){return _vm.setImageError()}}})]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"produktname"},[_c('h3',[_c('router-link',{attrs:{"to":{
          name: 'Produktdetail',
          params: { warengruppe: _vm.warengruppe, artikelid: _vm.produkt.id },
        }}},[_vm._v(_vm._s(_vm.produkt.name))])],1)]),_c('h4',{staticClass:"hersteller"},[_vm._v(_vm._s(_vm.produkt.hersteller))]),_c('p',{staticClass:"price text-center"},[_c('del'),_vm._v(_vm._s(_vm._f("betrag")(_vm.produkt.bruttopreis))+" €")]),_c('p',{staticClass:"text-center",attrs:{"id":"mwst"}},[_vm._v(" inkl. "+_vm._s(_vm.produkt.umsatzsteuersatz)+"% MwSt. zzgl. "),_c('router-link',{attrs:{"to":"/about/versandinformationen"}},[_vm._v("Versandkosten")])],1),_c('p',{staticClass:"vergleich text-center"},[_vm._v(" Inhalt: "+_vm._s(_vm._f("gewicht_oder_volumen")(_vm.produkt.nettoinhalt))+_vm._s(_vm.nettoinhalteinheit)+" "),_c('span',{staticClass:"text-nowrap"},[_vm._v("("+_vm._s(_vm._f("betrag")(_vm.vergleichspreis))+" € / "+_vm._s(_vm.vergleichseinheit)+")")])]),_c('p',{staticClass:"text-center"},[_c('i',{staticClass:"fa fa-truck mr-1",class:_vm.lieferzeitfarbe()}),_vm._v(_vm._s(_vm.lieferzeit))]),_c('p',{staticClass:"buttons"},[_c('router-link',{staticClass:"btn btn-outline-secondary",attrs:{"to":{
          name: 'Produktdetail',
          params: { warengruppe: _vm.warengruppe, artikelid: _vm.produkt.id },
        }}},[_vm._v(" Produktdetails ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":!_vm.isLieferbar()},on:{"click":_vm.addToWarenkorb}},[_c('i',{staticClass:"fa fa-shopping-cart"}),_vm._v("In den Warenkorb ")])],1)]),(_vm.isSale)?_c('div',{staticClass:"ribbon sale"},[_c('div',{staticClass:"theribbon"},[_vm._v("SALE")]),_c('div',{staticClass:"ribbon-background"})]):_vm._e(),(_vm.isNeu)?_c('div',{staticClass:"ribbon new"},[_c('div',{staticClass:"theribbon"},[_vm._v("NEU")]),_c('div',{staticClass:"ribbon-background"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }