<template>
  <div id="app">
    <ShopHeaderTop />
    <ShopHeaderNavigation class="sticky-top" />
    <router-view :key="$route.fullPath"/>
    <ShopFooter />
    <div v-if="!this.cookieconsent  " class="cookieconsent">
      <p>
        Wir respektieren Ihre Privatsphäre und setzen nur essentielle Cookies
        ein, die für das Funktionieren dieser Website unerlässlich sind. Durch
        die Nutzung unserer Seite stimmen Sie der Verwendung dieser Cookies zu.
      </p>
      <button type="button" class="btn btn-primary" @click="setcookieconsent()">
        Einverstanden
      </button>
    </div>
  </div>
</template>
<script>
import ShopHeaderTop from "@/components/ShopHeaderTop.vue";
import ShopHeaderNavigation from "@/components/ShopHeaderNavigation.vue";
import ShopFooter from "@/components/ShopFooter.vue";

export default {
  name: "App",
  data: function () {
    return {
      cookieconsent: false,
    };
  },
  components: {
    ShopHeaderTop,
    ShopHeaderNavigation,
    ShopFooter,
  },
  methods: {
    setcookieconsent() {
      this.cookieconsent = "true";
      localStorage.setItem("cookieconsent", this.cookieconsent);
    },
  },
  async beforeMount() {
    console.log("enter App.vue beforeMount");
    //var self = this;
    await this.$store.dispatch("keyparameter/initKeytab");
    console.log("keyparameter/initKeytab finished");
    // this.$store.dispatch("loadSession").then(function () {
    //   console.log("loadSession then");
    //   self.$store.dispatch("loadWarenkorb");
    // });
    await this.$store.dispatch("loadSession");
    console.log("loadSession finished");
    await this.$store.dispatch("loadWarenkorb");
    console.log("loadWarenkorb finished");
    console.log("leave App.vue beforeMount");
  },
  async mounted() {
    console.log("App.vue mounted");
    this.cookieconsent = localStorage.getItem("cookieconsent") != null;
  },
};
</script>

<style>
#app {
  font-family: Satoshi-Medium, "Source Sans Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.75rem;
  color: #2c3e50;
  /*background-color: #d7e1f7;*/
  background-color: #fff;
}

body {
  min-height: 100vh; 
}

.cookieconsent {
  position: fixed;
  padding: 20px;
  left: 0;
  bottom: 0;
  background-color: #dfd5af;
  color: black;
  text-align: center;
  width: 100%;
  z-index: 99999;
  animation-name: consentmove;
  animation-duration: 3s;
  animation-iteration-count: 1;
}

@keyframes consentmove {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}
</style>
