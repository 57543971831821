/* bestellung.store.js */

// State object
const initialState = () => ({
  adressdaten: {
    "rechnunggleichliefer": true,
    "rechnungsadresse": {
      "anrede": null,
      "name": "",
      "vorname": "",
      "adresszusatz": "",
      "strasse": "",
      "plz": "",
      "ort": "",
      "land": "DEU",
      "telefon": "",
      "email": ""
    },
    "lieferadresse": {
      "anrede": null,
      "name": "",
      "vorname": "",
      "adresszusatz": "",
      "strasse": "",
      "plz": "",
      "ort": "",
      "land": "DEU",
      "telefon": "",
    }
  },
  "versandartid": null,
  "zahlungsartid": null
});

const state = initialState();

// Getter functions
const getters = {
  getAdressdaten: state => {
    return state.adressdaten;
  },
  getZahlungsartId: state => {
    return state.zahlungsartid;
  },
  getVersandartId: state => {
    return state.versandartid;
  }

}

// Actions 
const actions = {

}

// Mutations
const mutations = {
  setAdressdaten(state, {rechnungsadresse, lieferadresse, rechnunggleichliefer}) {
    state.adressdaten.rechnungsadresse = rechnungsadresse;
    state.adressdaten.lieferadresse = lieferadresse;
    state.adressdaten.rechnunggleichliefer = rechnunggleichliefer;
  },
  setVersandartId(state, id) {
    state.versandartid = id;
  },
  setZahlungsartId(state, id) {
    state.zahlungsartid = id;
  },
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
          state[key] = newState[key]
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}