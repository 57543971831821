<template>
  <div id="shopheadertop">
    <!--
      *** TOPBAR ***
      _________________________________________________________
      -->
    <div id="top" class="header">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offer mb-3 mb-lg-0">
            <a href="#" class="btn btn-danger btn-sm">Dieser Onlineshop befindet sich im Probebetrieb - es sind noch keine Bestellungen möglich!</a>
          </div>
          <div class="col-lg-6 text-center text-lg-right">
            <ul class="menu list-inline mb-0">
              <li class="list-inline-item" v-if="isAbgemeldet">
                <router-link
                  :to="{
                    name: 'Login',
                    params: {
                      herkunft: $route.name,
                      warengruppe: $route.params.warengruppe,
                      artikelid: $route.params.artikelid,
                    },
                  }"
                  >Anmelden</router-link
                >
              </li>
              <li class="list-inline-item" v-if="isAbgemeldet">
                <router-link to="/register">Registrierung</router-link>
              </li>
              <li class="list-inline-item" v-if="isAngemeldet">
                <span class="displaybenutzername">{{ benutzername }}</span>
              </li>
              <li class="list-inline-item" v-if="isAngemeldet">
                <a href="#" @click="abmelden()">Abmelden</a>
              </li>
              <li class="list-inline-item">
                <router-link to="/kontakt">Kontakt</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- *** TOP BAR END ***-->
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopHeaderTop",
  computed: {
    isAngemeldet() {
      return this.$store.getters.isAngemeldet;
    },
    isAbgemeldet() {
      return !this.$store.getters.isAngemeldet;
    },
    benutzername() {
      return this.$store.getters.getBenutzername;
    },
  },
  methods: {
    abmelden() {
      this.$store.dispatch("abmelden");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.displaybenutzername {
  color: black;
}
</style>