<template>
  <div id="all">
    <div id="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- breadcrumb-->
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="nav-link">Home</router-link>
                </li>
                <li aria-current="page" class="breadcrumb-item active">
                  {{ this.$route.name }}
                </li>
              </ol>
            </nav>
          </div>
          <WarenkorbBox :status="getWarenkorbstatus"/>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import WarenkorbBox from "@/components/WarenkorbBox.vue";
export default {
  name: "ShopWarenkorb",
  components: {WarenkorbBox},
  computed: {
    getWarenkorbstatus() {
      if (this.$route.params.status === "pruefen") {
        return "pruefen";
      }
      return "normal";
    }
  }
};
</script>
<style scoped>
.nav-link {
  padding: 0;
}
</style>