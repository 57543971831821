export const warenkorballgemein = {
    data: function () {
        return {
            produktimageerror: false,
        };
    },
    methods: {
        getProduktImage(img) {
            if (typeof (img) != "undefined") {
                // console.log("try to load image: " + "./api/img/products/" + img + ".webp");
                return "./api/img/products/" + img + ".webp";
            }
        },
        getProduktListImage(img) {
            if (typeof (img) != "undefined") {
                // console.log("try to load image: " + "./api/img/products/" + img + ".webp");
                return "./api/img/products/" + img + ".webp";
            }
        },
        getDefaultProduktImage() {
            return "./api/img/products/default-produkt.webp";
        },
        getProduktImageWithDefault(img) {
            if (img === null || img === undefined || (img !== null && img.length === 0) || this.produktimageerror) {
                return this.getDefaultProduktImage();
            }
            return this.getProduktImage(img);
        },
        getProduktListImageWithDefault(img) {
            if (img === null || img === undefined || (img !== null && img.length === 0) || this.produktimageerror) {
                return this.getDefaultProduktImage();
            }
            return this.getProduktListImage(img);
        },
        setImageError() {
            this.produktimageerror = true;
        }
    },
}