function getWarenkorbHash() {
    if (localStorage.getItem("warenkorb")) {
        return localStorage.getItem("warenkorb");
    }
    console.log("localStorage warenkorb is empty");
    return "";
}

function saveWarenkorbHash(warenkorbhash) {
    localStorage.setItem("warenkorb", warenkorbhash);
}

function getWarenkorbKnown() {
    if (localStorage.getItem("warenkorbknown")) {
        return parseInt(localStorage.getItem("warenkorbknown"));
    }
    return "";
}

function setWarenkorbDaten(known, warenkorbhash) {
    console.log("setWarenkorbDaten: known=" + known + " warenkorbhash=" + warenkorbhash);
    localStorage.setItem("warenkorb", warenkorbhash);
    localStorage.setItem("warenkorbknown", known);
}

function getAnmeldedaten() {
    if (localStorage.getItem("anmeldung")) {
        return localStorage.getItem("anmeldung");
    }
    console.log("getAnmeldedaten localStorage is empty: " + localStorage.getItem("anmeldung"));
    return "";
}
function setAnmeldedaten(jwt) {
    localStorage.setItem("anmeldung", jwt);
}

function deleteWarenkorb() {
    console.log("deleteWarenkorb localStorage");
    localStorage.removeItem("warenkorb");
    localStorage.removeItem("warenkorbknown");
}

function deleteAnmeldung() {
    console.log("deleteAnmeldung localStorage");
    localStorage.removeItem("anmeldung");
}

export { getWarenkorbHash, saveWarenkorbHash , setAnmeldedaten, getAnmeldedaten, deleteWarenkorb, deleteAnmeldung, getWarenkorbKnown, setWarenkorbDaten }