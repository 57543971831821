<template>
  <ProduktlisteLayout :listentyp="listentyp" :warengruppe="produkt.warengruppe" :artikel="produkt">
    <div id="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 order-1 order-lg-2">
            <div id="productMain" class="row">
              <div class="col-sm-6">
                <div class="item text-center">
                  <img
                    :src="getProduktListImageWithDefault(produkt.img)"
                    @error="setImageError()"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="col-sm-6 mt-3 mt-sm-0">
                <!-- <div class="box"> -->
                <div>
                  <h3 class="text-center">{{ produkt.name }}</h3>
                  <h4 class="text-center hersteller">
                    {{ produkt.hersteller }}
                  </h4>
                  <p class="price">
                    {{ produkt.bruttopreis | betrag }} &euro;&nbsp;
                  </p>
                  <p id="mwst" class="text-center">
                    inkl. {{ produkt.umsatzsteuersatz }}% MwSt. zzgl.
                    <router-link to="/about/versandinformationen"
                      >Versandkosten</router-link
                    >
                  </p>
                  <p class="vergleich text-center">
                    Inhalt:&nbsp;{{ produkt.nettoinhalt | gewicht_oder_volumen
                    }}{{ nettoinhalteinheit }}&nbsp;<span class="text-nowrap"
                      >({{ vergleichspreis | betrag }} &euro; /
                      {{ vergleichseinheit }})</span
                    >
                  </p>
                  <p class="text-center">
                    <i class="fa fa-truck mr-1" :class="lieferzeitfarbe()"></i
                    >{{ lieferzeit }}
                  </p>
                  <p class="text-center buttons">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="addToWarenkorb"
                      :disabled="!isLieferbar()"
                    >
                      <i class="fa fa-shopping-cart"></i> In den Warenkorb
                    </button>
                  </p>
                </div>
              </div>
              <div class="ribbon sale" v-if="isSale">
                <div class="theribbon">SALE</div>
                <div class="ribbon-background"></div>
              </div>
              <div class="ribbon new" v-if="isNeu">
                <div class="theribbon">NEU</div>
                <div class="ribbon-background"></div>
              </div>
            </div>
            <!-- <div id="details" class="box"> -->
            <div id="details" class="row">
              <div class="col-sm-6">
                <h4>Produktdaten und Hinweise</h4>
                <p><span v-html="produkt.beschreibung"></span></p>
                <br />
                <h4>Zutatenliste</h4>
                <p><span v-html="produkt.zutatenliste"></span></p>
                <br />
                <h4>Allergiehinweise</h4>
                <p><span v-html="produkt.allergene"></span></p>
                <br />
                <h4>Importeur</h4>
                <p><span v-html="importeur_text"></span></p>
              </div>
              <div class="col-sm-6 mt-3 mt-sm-0">
                <h4>Durchschnittliche N&auml;hrwertangaben pro
                        {{ produkt.naehrwertrichtwert }}</h4>
                <table class="table table-striped table-sm table-bordered">
                  <tbody>
                    <tr>
                      <th scope="col" class="w-25">Energie</th>
                      <td scope="col">
                        {{ produkt.brennwertkj }} kJ /
                        {{ produkt.brennwertkcal }} kcal
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" class="w-25">Fett</th>
                      <td scope="col">
                        {{ produkt.fett | naehrwertangabe }} g
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" class="w-25">
                        - davon gesättigte Fettsäuren
                      </th>
                      <td scope="col">
                        {{ produkt.gesfettsaeuren | naehrwertangabe }} g
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" class="w-25">Kohlenhydrate</th>
                      <td scope="col">
                        {{ produkt.kohlenhydrate | naehrwertangabe }} g
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" class="w-25">- davon Zucker</th>
                      <td scope="col">
                        {{ produkt.zucker | naehrwertangabe }} g
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" class="w-25">Eiweiss</th>
                      <td scope="col">
                        {{ produkt.eiweiss | naehrwertangabe }} g
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" class="w-25">Salz</th>
                      <td scope="col">
                        {{ produkt.salz | naehrwertangabe }} g
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- /.col-md-9-->
        </div>
        <div class="row" style="margin-top: 10px; margin-bottom: 10px;">
          <div class="col-md-12">
          <p>
            * Gilt für Lieferungen nach Deutschland. Lieferzeiten für andere
            Länder und Informationen zur Berechnung des Liefertermins siehe
            hier: Lieferzeiten. Werktage sind die Tage von Montag bis Freitag.
          </p>
        </div>
        </div>
      </div>
    </div>
  </ProduktlisteLayout>
</template>
<script>
import $ from "jquery";
import { produktmethods } from "@/mixins/produkt_methods.js";
import { standardfilters } from "@/mixins/standard_filters.js";
import { warenkorballgemein } from "@/mixins/warenkorb_allgemein.js";
import ProduktlisteLayout from "@/layout/ProduktlisteLayout.vue";
export default {
  name: "ShopProduktdetail",
  components: { ProduktlisteLayout },
  mixins: [produktmethods, standardfilters, warenkorballgemein],
  data: function () {
    return {
      produkt: {},
    };
  },
  computed: {
    importeur_text() {
      let imp = this.$store.getters["keyparameter/getImporteur"](this.produkt.importeur);
      return imp.name + "<BR />" + imp.strasse + "<BR />" + imp.plz + " " + imp.ort + "<BR />" + imp.land;
    },
    listentyp() {
      return "WG";
    }
  },
  methods: {
    loadProduct(artikelid) {
      console.log("loadProduct artikelid=" + artikelid);
      let serviceparams = {
        filter: { artikelid: artikelid },
      };
      let self = this;
      $.ajax({
        url: "./api/getproduct.php",
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify(serviceparams),
        success: function (data) {
          console.log("loadProduct SUCCESS");
          self.produkt = data.produkt;
        },
        error: function (error) {
          console.log("loadProduct ERROR");
          console.log(error);
        },
      });
    },
  },
  watch: {
    $route(to) {
      if (to.name === "Produktliste") {
        console.log(
          "Produktdetail watch: warengruppe=" + this.$route.params.warengruppe
        );
        this.loadProduct(this.$route.params.artikelid);
      }
    },
  },
  created() {
    console.log(
      "Produktdetail created: warengruppe=" + this.$route.params.warengruppe
    );
    this.loadProduct(this.$route.params.artikelid);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.col-eins {
  width: 25%;
}

.col-zwei {
  width: 50%;
}
/*.box h3 {
  font-size: 0.95rem;
}*/
#content #productMain .price {
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 20px;
}
#content #productMain .buttons {
  margin-top: 20px;
}
.vergleich {
  text-align: center;
  font-size: 0.9rem;
}
p {
  margin-bottom: 0.25rem;
}
.box .nettoinhalt {
  text-align: center;
  font-size: 1rem;
}
#mwst {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

h4.hersteller {
  font-size: 0.9rem;
}
</style>