<template>
<div>
    <div class="row">
        <div class="col-lg-12">
            <div>
                <div class="row">
                    <div class="col-lg-6 bg-white mt-lg-3 mt-4">
                        <h4>Ich bin bereits Kunde.</h4>
                        <hr />
                        <form>
                            <div class="form-group mb-3">
                                <label for="email">E-Mailadresse</label>
                                <input class="form-control" id="email" type="text" v-model="anmeldung.benutzername" required name="username" autocomplete="username">
                            </div>
                            <div class="form-group mb-4">
                                <label for="password">Passwort</label>
                                <input class="form-control" id="password" type="password" v-model="anmeldung.passwort" v-on:keyup.enter="anmelden()" required name="password" autocomplete="current-password">
                            </div>
                            <div v-if="isMessageVorhanden" class="form-group text-danger">
                                {{ anmeldung.message }}
                            </div>
                            <button class="btn btn-primary mb-3" type="button" @click="anmelden()">
                                Anmelden</button>
                            <div class="float-right"><a class="text-sm text-paleBlue" href="#">Passwort vergessen?</a></div>

                            <div><a href="#" style="font-size:1.0rem" @click.prevent="weiterAlsGast()">Ich möchte als Gast bestellen.</a></div>

                        </form>
                    </div>
                    <div class="col-lg-6 bg-white mt-lg-3 mt-4">
                        <h4>Ich bin neuer Kunde und möchte mich registrieren.</h4>
                        <hr />
                        <form class="mt-lg-4">
                            <router-link :to="{ name: 'Registrierung'}" class="btn btn-primary mb-3">
                                Registrieren</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box-footer d-flex justify-content-between flex-lg-row">
        <div class="left">
            <router-link :to="{
            name: 'Home',
          }" class="btn btn-outline-secondary"><i class="fa fa-chevron-left"></i> Weiter einkaufen</router-link>
        </div>
    </div>
</div>
</template>

<script>
import {
    serviceAnmelden
} from "@/api/session.api.js";
export default {
    name: "ShopCheckoutAnmeldung",
    data: function () {
        return {
            anmeldung: {
                benutzername: "",
                passwort: "",
                message: "",
            },
        };
    },
    computed: {
        isMessageVorhanden() {
            return this.anmeldung.message != "";
        },
    },
    methods: {
        async anmelden() {
            try {
                console.log("method anmelden");
                const response = await serviceAnmelden(
                    this.anmeldung.benutzername,
                    this.anmeldung.passwort,
                    this.$store.getters.getWarenkorbHash
                );
                if (response.data.successful) {
                    // Anmeldung erfolgreich
                    this.$store.dispatch("anmelden", {
                        jwt: response.data.jwt,
                        warenkorbhash: response.data.warenkorbhash,
                        benutzername: response.data.benutzername,
                    });
                    if (response.data.warenkorbchanged) {
                        this.$router.push({
                            name: "Warenkorb",
                            params: {
                                status: "pruefen"
                            }
                        });
                    } else {
                        this.$router.push({
                            name: "BestellungAdresse"
                        });
                    }
                } else {
                    this.anmeldung.message =
                        "Kombination aus Benutzername/Passwort existiert nicht.";
                }
            } catch (error) {
                console.log("Error ShopLogin: " + error);
            }

        },
        weiterAlsGast() {
            this.$router.push({
                name: "BestellungAdresse"
            });
        },
    },
};
</script>

<style scoped>
.nav-link {
    padding: 0;
}

.clickicon {
    cursor: pointer;
}

.warenkorb-summe {
    padding: 3px 12px;
}

.warenkorb-versandkosten {
    border-top: 0px solid white;
    padding: 3px 12px;
}

.warenkorb-gesamtpreis {
    border-top: 1px solid #dee2e6;
    border-bottom: 5px double #dee2e6;
    padding: 3px 12px;
}

.showbuttons {
    display: none;
}

.showboxes {
    display: flex;
}

@media only screen and (max-width: 767px) {
    .showbuttons {
        display: flex;
        margin-bottom: 10px;
    }

    .showboxes {
        display: none;
    }
}
</style>
