export const standardfilters = {
    filters: {
        betrag(wert) {
            return (wert / 100).toFixed(2).replace(".", ",");
        },
        gewicht_oder_volumen(wert) {
            if (wert >= 10000) {
                return (wert / 10000).toFixed(2).replace(".", ",");
            }
            if (wert % 10 === 0) {
                return wert / 10;
            } else {
                return (wert / 10).toFixed(1).replace(".",",");
            }
        },
        naehrwertangabe(wert) {
            return (wert / 100).toFixed(2).replace(".", ",");
        },
        kilogramm(wert) {
            return (wert / 1000).toFixed(3).replace(".",",");
        }
    },
}