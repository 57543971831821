import httpClient from '@/api/httpClient.js'

const END_POINT = './api/anmelden.php';
const END_POINT_RESET = './api/pwreset.php';

const serviceAnmelden = (benutzername, passwort, warenkorbhash) => httpClient.post(END_POINT, { benutzername, passwort, warenkorbhash });
const serviceRequestReset = (benutzername) => httpClient.post(END_POINT_RESET, { aktion: "REQ" , benutzername});
const serviceResetPasswort = (passwort, passwortwdh, pwresetcode) => httpClient.post(END_POINT_RESET, { aktion: "RES" , passwort, passwortwdh, pwresetcode});
export {
    serviceAnmelden, serviceRequestReset, serviceResetPasswort
}