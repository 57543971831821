<template>
<ShopProduktlisteTyp :listentyp="listentyp"></ShopProduktlisteTyp>
</template>
<script>
import ShopProduktlisteTyp from "@/components/ShopProduktlisteTyp.vue";
export default {
    name: 'ShopProduktlisteWarengruppe',
    components: { ShopProduktlisteTyp },
    computed: {
        listentyp() {
            return "WG";
        }
    }
}
</script>
