/* keyparameter.store.js */

import { getSchluesselwerte } from "@/api/keytab.api.js";

// State object
const state = {
    eulaender: [
        { text: "Deutschland", value: "DEU", id: 1 },
        { text: "Belgien", value: "BEL", id: 2 },
        { text: "Bulgarien", value: "BGR", id: 3 },
        { text: "Dänemark", value: "DNK", id: 4 },
        { text: "Estland", value: "EST", id: 5 },
        { text: "Finnland", value: "FIN", id: 6 },
        { text: "Frankreich", value: "FRA", id: 7 },
        { text: "Griechenland", value: "GRC", id: 8 },
        { text: "Irland", value: "IRL", id: 9 },
        { text: "Italien", value: "ITA", id: 10 },
        { text: "Kroatien", value: "HRV", id: 11 },
        { text: "Lettland", value: "LVA", id: 12 },
        { text: "Litauen", value: "LTU", id: 13 },
        { text: "Luxemburg", value: "LUX", id: 14 },
        { text: "Malta", value: "MLT", id: 15 },
        { text: "Monaco", value: "MCO", id: 16 },
        { text: "Niederlande", value: "NLD", id: 17 },
        { text: "Österreich", value: "AUT", id: 18 },
        { text: "Polen", value: "POL", id: 19 },
        { text: "Portugal", value: "PRT", id: 20 },
        { text: "Rumänien", value: "ROM", id: 21 },
        { text: "Schweden", value: "SWE", id: 22 },
        { text: "Slowakei", value: "SVK", id: 23 },
        { text: "Slowenien", value: "SVN", id: 24 },
        { text: "Spanien", value: "ESP", id: 25 },
        { text: "Tschechien", value: "CZE", id: 26 },
        { text: "Ungarn", value: "HUN", id: 27 },
        { text: "Zypern", value: "CYP", id: 28 }
    ],
    anreden: [
        { text: "Frau", ausgabetext: "Frau", value: 1, id: 1 },
        { text: "Herr", ausgabetext: "Herr", value: 2, id: 2 },
        { text: "Keine Anrede", ausgabetext: "", value: 3, id: 3 },
    ],
    versandarten: [
        { id: 1, titel: "Selbstabholung", beschreibung: "Holen Sie Ihre bestellten Waren bei uns ab.", versandkosten: 0},
        { id: 2, titel: "Paketversand", beschreibung: "Versand über DHL oder Hermes Germany", versandkosten: 699}
    ],
    zahlungsarten: [
        { id: 1, beschreibung: "Vorkasse (Überweisung, PayPal)"},
        { id: 2, beschreibung: "Barzahlung bei Selbstabholung"}
    ],
    warengruppen: [],
    importeure: []
}


// Getter functions
const getters = {
    getEULand: (state) => (laenderkuerzel) => {
        return state.eulaender.find(landarr => landarr.value === laenderkuerzel);
    },
    getEULaender: state => {
        return state.eulaender;
    },
    getAnreden: (state) => {
        return state.anreden;
    },
    getAnrede: (state) => (anredeid) => {
        if (anredeid != null) {
            return state.anreden.find(anredearr => anredearr.value === anredeid);
        }
        return { "ausgabetext": "" };
    },
    getVersandarten: state => {
        return state.versandarten;
    },
    getVersandart: (state) => (versandartid) => {
        let versandart = state.versandarten.find(versandart => versandart.id === versandartid);
        if (!versandart) {
            versandart = { id: 0, titel: "", beschreibung: "", versandkosten: 0, typ: ""};
        }
        return versandart;
    },
    getZahlungsarten: state => {
        return state.zahlungsarten;
    },
    getZahlungsartenErlaubt: state => (erlaubteZahlungsartenArray) => {
        return state.zahlungsarten.filter(zahlungsart => erlaubteZahlungsartenArray.includes(zahlungsart.id));
    },
    getZahlungsart: (state) => (zahlungsartid) => {
        let zahlungsart = state.zahlungsarten.find(zahlungsart => zahlungsart.id === zahlungsartid);
        if (!zahlungsart) {
            zahlungsart = { id: 0, beschreibung: ""};
        }
        return zahlungsart;
    },
    getWarengruppe: (state) => (warengruppename) => {
        console.log("getWarengruppe");
        let warengruppe = state.warengruppen.find(warengruppe => warengruppe.name === warengruppename);
        if (!warengruppe) {
            warengruppe = { name: "unknown" , bezeichnung: "unknown" };
        }
        return warengruppe;
    },
    getWarengruppen: state => { return state.warengruppen; },
    getImporteur: (state) => (kurzname) => {
        let importeur = state.importeure.find(imp => imp.kurzname === kurzname);
        if (!importeur) {
            importeur = { name: "unknown", strasse: "unknown", plz: "unknown", ort: "unknown", land: "unknown"};
        }
        return importeur;
    }
}


// Actions 
const actions = {
    initKeytab({commit}) {
        getSchluesselwerte("warengruppen,versandarten,zahlungsarten,importeure").then(function (response) {
            commit("setVersandarten", response.data.schluessel.versandarten);
            commit("setZahlungsarten", response.data.schluessel.zahlungsarten);
            commit("setWarengruppen", response.data.schluessel.warengruppen);
            commit("setImporteure", response.data.schluessel.importeure);
          });
    }
}

// Mutations
const mutations = {
    setVersandarten(state, versandarten) {
        state.versandarten = versandarten;
    },
    setZahlungsarten(state, zahlungsarten) {
        state.zahlungsarten = zahlungsarten;
    },
    setWarengruppen(state, warengruppen) {
        state.warengruppen = warengruppen;
    },
    setImporteure(state, importeure) {
        state.importeure = importeure;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}