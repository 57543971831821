const saveScrollPosition = (routeName) => {
    const scrollPosition = {
        x: window.scrollX,
        y: window.scrollY
    };
    sessionStorage.setItem(`SP-${routeName}`, JSON.stringify(scrollPosition));
};

const restoreScrollPosition = (routeName) => {
    console.log("TRY restoreScrollPostion for routeName=" + routeName);
    const scrollPosition = JSON.parse(sessionStorage.getItem(`SP-${routeName}`));
    if (scrollPosition) {
        console.log("restoreScrollPosition scrollTo: " + JSON.stringify(scrollPosition));
        window.scrollTo(scrollPosition.x, scrollPosition.y);
    }
}

const getScrollPosition = (routeName) => {
    const scrollPosition = JSON.parse(sessionStorage.getItem(`SP-${routeName}`));
    if (scrollPosition) {
        return scrollPosition;
    }
    return { x: 0, y: 0 };
}

const removeScrollPosition = (routeName) => {
    console.log("removeScrollPositiion " + routeName);
    sessionStorage.removeItem(`SP-${routeName}`);
}

export { saveScrollPosition, restoreScrollPosition, getScrollPosition, removeScrollPosition }