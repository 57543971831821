import httpClient from '@/api/httpClient.js'

const END_POINT = './api/register.php';
const END_POINT_ACTIVATE = './api/registeraktivieren.php';

const registerBenutzer = (emailadresse, emailadressewiederholung, passwort, zustimmung, warenkorbhash) => httpClient.post(END_POINT, { emailadresse, emailadressewiederholung, passwort, zustimmung, warenkorbhash });
const activateBenutzer = (activationcode) => httpClient.post(END_POINT_ACTIVATE, { activationcode });

export {
    registerBenutzer, activateBenutzer
}