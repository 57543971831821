<template>
  <div class="box box-adresse">
    <h4>{{ titel }}</h4>
    <hr class="d-none d-md-block"/>
    <form class="needs-validation" novalidate>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="anrede">Anrede *</label>
          <select
            class="form-control"
            v-model="adresse.anrede"
            v-bind:class="{ 'is-invalid': !validierung.anrede.valid }"
          >
          <option v-for="option in anreden" v-bind:value="option.value" v-bind:key="option.id">
            {{ option.text }}
          </option>
          </select>
          <!--           <input
            id="anrede"
            type="text"
            class="form-control"
            required
            v-model="adresse.anrede"
            v-bind:class="{ 'is-invalid': !validierung.anrede.valid }"
          /> -->
          <div class="invalid-feedback">
            {{ validierung.anrede.feedback }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="vorname">Vorname *</label>
          <input
            id="vorname"
            type="text"
            class="form-control"
            required
            v-model="adresse.vorname"
            v-bind:class="{ 'is-invalid': !validierung.vorname.valid }"
            name="fname" autocomplete="given-name"
          />
          <div class="invalid-feedback">
            {{ validierung.vorname.feedback }}
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="name">Name *</label>
          <input
            id="name"
            type="text"
            class="form-control"
            required
            v-model="adresse.name"
            v-bind:class="{ 'is-invalid': !validierung.name.valid }"
            name="lname" autocomplete="family-name"
          />
          <div class="invalid-feedback">
            {{ validierung.name.feedback }}
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="adresszusatz">Adresszusatz</label>
          <input
            id="adresszusatz"
            type="text"
            class="form-control"
            required
            v-model="adresse.adresszusatz"
            v-bind:class="{ 'is-invalid': !validierung.adresszusatz.valid }"
          />
          <div class="invalid-feedback">
            {{ validierung.adresszusatz.feedback }}
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="strassehausnummer">Straße und Hausnummer *</label>
          <input
            id="strassehausnummer"
            type="text"
            class="form-control"
            required
            v-model="adresse.strasse"
            v-bind:class="{
              'is-invalid': !validierung.strasse.valid,
            }"
            name="address" autocomplete="street-address"
          />
          <div class="invalid-feedback">
            {{ validierung.strasse.feedback }}
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="plz">Postleitzahl *</label>
          <input
            id="plz"
            type="text"
            class="form-control"
            required
            v-model="adresse.plz"
            v-bind:class="{ 'is-invalid': !validierung.plz.valid }"
            name="zip" autocomplete="postal-code"
          />
          <div class="invalid-feedback">
            {{ validierung.plz.feedback }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="ort">Ort *</label>
          <input
            id="ort"
            type="text"
            class="form-control"
            required
            v-model="adresse.ort"
            v-bind:class="{ 'is-invalid': !validierung.ort.valid }"
            name="city" autocomplete="address-level2"
          />
          <div class="invalid-feedback">
            {{ validierung.ort.feedback }}
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="land">Land *</label>
          <select
            id="land"
            class="form-control"
            required
            v-model="adresse.land"
            v-bind:class="{ 'is-invalid': !validierung.land.valid }">
          <option v-for="option in eulaender" v-bind:value="option.value" v-bind:key="option.id">
            {{ option.text }}
          </option>
          </select>
          <div class="invalid-feedback">
            {{ validierung.land.feedback }}
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="telefon">Telefonnummer</label>
          <input
            id="telefon"
            type="text"
            class="form-control"
            required
            v-model="adresse.telefon"
            v-bind:class="{ 'is-invalid': !validierung.telefon.valid }"
            name="phone" autocomplete="tel"
          />
          <div class="invalid-feedback">
            {{ validierung.telefon.feedback }}
          </div>
        </div>
      </div>
      <div class="form-row" v-if="has_email">
        <div class="form-group col-md-6">
          <label for="telefon">E-Mail *</label>
          <input
            id="email"
            type="text"
            class="form-control"
            required
            v-model="adresse.email"
            v-bind:class="{ 'is-invalid': !validierung.email.valid }"
            name="email" autocomplete="email"
          />
          <div class="invalid-feedback">
            {{ validierung.email.feedback }}
          </div>
        </div>
      </div>        
      <div class="form-check" v-if="checkboxtext != undefined">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="internalcheckboxvalue"
          id="defaultCheck1"
          v-on:change="checkboxchanged($event)"
        />
        <label class="form-check-label" for="defaultCheck1">
          {{ checkboxtext }}
        </label>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AdressBox",
  props: {
    titel: String,
    adresse: Object,
    validierung: Object,
    checkboxtext: String,
    checkboxvalue: Boolean,
    has_email: Boolean
  },
  data: function () {
    return {
      internalcheckboxvalue: this.checkboxvalue,
    };
  },
  computed: {
    eulaender() {
      return this.$store.getters['keyparameter/getEULaender'];
    },
    anreden() {
      return this.$store.getters['keyparameter/getAnreden'];
    }
  },
  methods: {
    checkboxchanged(ev) {
      this.$emit("checkbox-changed", ev.target.checked);
    },
  },
  watch: {
    checkboxvalue: function (newVal, oldVal) {
      // watch it
      console.log("Prop checkboxvalue changed: ", newVal, " | was: ", oldVal);
      this.internalcheckboxvalue = newVal;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>