<template>
    <div id="all">
      <div id="content">
        <div class="container-lg">
          <div class="row">
            <div class="col-lg-12">
              <!-- breadcrumb-->
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb"> 
                  <li class="breadcrumb-item"><router-link to="/" class="nav-link">Home</router-link></li>
                  <li aria-current="page" class="breadcrumb-item">
                    <router-link v-if="isListentypWarengruppe" :to="{ name: 'ProduktlisteWarengruppe' , params: { 'warengruppe': this.warengruppeForLink }}" class="nav-link">{{this.warengruppenbezeichnung}}</router-link>
                    <a v-else href="#" @click="$router.go(-1)" class="nav-link">Suche</a>
                  </li>
                  <li v-if="!!artikel" class="breadcrumb-item active">{{artikelbezeichnung}}</li>
                </ol>
              </nav>
            </div>
            <div class="col-lg-12">
              <slot></slot>
            </div>
            <!-- /.col-lg-9-->
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  // @ is an alias to /src
  
  export default {
    name: 'ProduktlisteLayout',
    components: { },
    props: { listentyp: String, warengruppe: String , artikel: Object },
    computed: {
      isListentypWarengruppe() {
        return this.listentyp === "WG"
      },
      warengruppenbezeichnung() {
        return this.$store.getters["keyparameter/getWarengruppe"](this.warengruppe).bezeichnung;
      },
      artikelbezeichnung() {
        if (this.artikel) {
          return this.artikel.name;
        }
        return "";
      },
      warengruppeForLink() {
        if (this.warengruppe) {
          return this.warengruppe;
        }
        return " ";
      }
    }
  }
  </script>
  <style scoped>
  .nav-link { padding: 0; display: inline; }

  .breadcrumb {
    box-shadow: 0;
    
  }
  </style>