<template>
<div class="login-page">
    <div class="container d-flex align-items-center position-relative py-5">
        <div class="card shadow-sm w-100 rounded overflow-hidden bg-none">
            <div class="card-body p-0">
                 <div v-if="showSuccess" class="row gx-0">
                    <div class="col-lg-6">
                        <div class="info d-flex justify-content-center flex-column p-4">
                            <div class="py-1">
                                <h1>Registrierung fast abgeschlossen</h1>
                                <p>Bitte E-Mail-Postfach prüfen.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 bg-white">
                        <div class="d-flex align-items-center px-4 px-lg-5 h-100">
                            <div class="p-4">
                                <p class="lead">Vielen Dank f&uuml;r Ihre Registrierung!</p>
                                <p>
                                    Um Ihre Registrierung abzuschlie&szlig;en, haben wir eine E-Mail an die von Ihnen angegebene Adresse gesendet.
                                    Bitte &uuml;berpr&uuml;fen Sie Ihr E-Mail-Postfach und klicken auf den darin enthaltenen Link, um Ihre Registrierung zu best&auml;tigen.
                                </p>
                                <p>
                                    Falls die E-Mail nicht innerhalb der n&auml;chsten Minuten ankommt, &uuml;berpr&uuml;fen Sie bitte auch Ihren Spam-Ordner.
                                    Manchmal landen unsere Nachrichten dort versehentlich.
                                </p>
                                <p>
                                    Wir freuen uns darauf, Sie bald als unseren Kunden begr&uuml;&szlig;en zu d&uuml;rfen.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div v-if="!showSuccess" class="row gx-0 align-items-stretch">
                    <div class="col-lg-6">
                        <div class="info d-flex justify-content-center flex-column p-4">
                            <div class="py-1">
                                <h1>Registrierung</h1>
                                <p>Wir freuen uns, Sie als neuen Kunden begrüßen zu dürfen.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Form Panel    -->
                    <div class="col-lg-6 bg-white">
                        <div class="d-flex align-items-center px-4 px-lg-5 h-100">
                            <form class="login-form py-5 w-100 needs-validation" novalidate>
                                <div class="form-group mb-3">
                                    <label for="email-new">E-Mailadresse *</label>
                                    <input id="email-new" type="text" class="form-control" v-bind:class="{ 'is-invalid': checkInvalid('emailadresse') }" required v-model="registrierung.emailadresse" name="email" autocomplete="email"/>
                                    <div class="invalid-feedback">
                                        {{ this.getInvalidMessage("emailadresse") }}
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="email-new-repeat">Wiederholung E-Mailadresse *</label>
                                    <input id="email-new-repeat" type="text" class="form-control" v-bind:class="{'is-invalid': checkInvalid('emailadressewiederholung')}" required v-model="registrierung.emailadressewiederholung" name="email" autocomplete="email"/>
                                    <div class="invalid-feedback">
                                        {{ this.getInvalidMessage("emailadressewiederholung") }}
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="password-new">Passwort *</label>
                                    <div class="input-group">
                                        <input id="password-new" :type="showPasswort ? 'text' : 'password'" class="form-control" v-bind:class="{ 'is-invalid': checkInvalid('passwort') }" required v-model="registrierung.passwort" v-on:keyup.enter="register()" name="password" autocomplete="off"/>
                                        <div class="input-group-prepend">
                                            <div class="input-group-text passwordeye" @click="toggleShowPasswort">
                                                <i class="fa" :class="{
                    'fa-eye-slash': !showPasswort,
                    'fa-eye': showPasswort,
                  }" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div class="invalid-feedback">
                                            {{ this.getInvalidMessage("passwort") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check mb-4">
                                    <input class="form-check-input" type="checkbox" v-model="registrierung.zustimmung" v-bind:class="{ 'is-invalid': checkInvalid('zustimmung') }" id="checkBedingungen" />
                                    <label class="form-check-label" for="checkBedingungen">
                                        Ich habe die
                                        <router-link to="/about/datenschutz" target="_blank">Datenschutzbestimmungen</router-link>
                                        gelesen und akzeptiere diese.
                                    </label>
                                    <div class="invalid-feedback">
                                        {{ this.getInvalidMessage("zustimmung") }}
                                    </div>
                                </div>
                                <button class="btn btn-primary mb-3" id="login" type="button" @click="register()">Registrieren</button><br><small class="text-gray-500">Bereits registriert? </small>
                                <router-link :to="{ name: 'Login'}" class="text-sm text-paleBlue">Anmelden</router-link>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    registerBenutzer
} from "@/api/register.api.js";
export default {
    name: 'ShopRegistrierung',
    data: function () {
        return {
            registrierung: {
                emailadresse: "",
                emailadressewiederholung: "",
                passwort: "",
                zustimmung: false,
            },
            feedback: {},
            showPasswort: false,
            showSuccess: false
        };
    },
    methods: {
        toggleShowPasswort() {
            this.showPasswort = !this.showPasswort;
        },
        register() {
            let self = this;
            registerBenutzer(this.registrierung.emailadresse, this.registrierung.emailadressewiederholung, this.registrierung.passwort,
                this.registrierung.zustimmung, this.$store.getters.getWarenkorbHash).then(function (response) {
                self.feedback = response.data.feedback;
                if (response.data.successful) {
                    // Registrierung erfolgreich
                    self.showSuccess = true;
                }
            });
        },
        checkInvalid(feldname) {
            if (Object.prototype.hasOwnProperty.call(this.feedback, feldname)) {
                return true;
            }
            return false;
        },
        getInvalidMessage(feldname) {
            if (Object.prototype.hasOwnProperty.call(this.feedback, feldname)) {
                return this.feedback[feldname];
            }
            return "";
        },
    },
    

}
</script>