<template>
<div class="login-page">
    <div class="container d-flex align-items-center position-relative py-5">
        <div class="card shadow-sm w-100 rounded overflow-hidden bg-none">
            <div class="card-body p-0">
                <div class="row gx-0 align-items-stretch">
                    <div class="col-lg-6">
                        <div class="info d-flex justify-content-center flex-column p-4">
                            <div class="py-1">
                                <h1>Passwort ändern</h1>
                                <p>Ändern Sie hier Ihr Passwort, wenn Sie über einen gültigen Link verfügen.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Form Panel    -->
                    <div class="col-lg-6 bg-white">
                        <div class="d-flex align-items-center px-4 px-lg-5 h-100">
                            <form class="login-form py-5 w-100 needs-validation" novalidate>
                                <div class="form-group mb-3">
                                    <label for="password-new">Passwort</label>
                                    <div class="input-group">
                                        <input id="password-new" :type="showPasswort ? 'text' : 'password'" class="form-control" v-bind:class="{ 'is-invalid': checkInvalid('passwort') }" required v-model="change.passwort" />
                                        <div class="input-group-prepend">
                                            <div class="input-group-text passwordeye" @click="toggleShowPasswort">
                                                <i class="fa" :class="{
                    'fa-eye-slash': !showPasswort,
                    'fa-eye': showPasswort,
                  }" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div class="invalid-feedback">
                                            {{ this.getInvalidMessage("passwort") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="password-wdh">Passwort wiederholen</label>
                                    <div class="input-group">
                                        <input id="password-wdh" type="password" class="form-control" v-bind:class="{ 'is-invalid': checkInvalid('passwortwdh') }" required v-model="change.passwortwdh" />
                                        <div class="invalid-feedback">
                                            {{ this.getInvalidMessage("passwortwdh") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                <button class="btn btn-primary mb-3" id="login" type="button" @click="changepw()" v-bind:class="{ 'is-invalid': checkInvalid('changepw') }">Passwort &auml;ndern</button>
                                <div class="invalid-feedback">
                                    {{ this.getInvalidMessage("changepw") }}
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    serviceResetPasswort
} from "@/api/session.api.js";
export default {
    name: 'ShopPasswortAendern',
    data: function () {
        return {
            change: {
                passwort: "",
                passwortwdh: ""
            },
            feedback: {},
            showPasswort: false,
        };
    },
    methods: {
        toggleShowPasswort() {
            this.showPasswort = !this.showPasswort;
        },
        async changepw() {
            const response = await serviceResetPasswort(this.change.passwort, this.change.passwortwdh, this.$route.query.pwresetcode);
            if (response.data.successful) {
                // Push to Login
                this.$router.push({name: 'Login'});
            } else {
                this.feedback = response.data.feedback;
            }
        },
        checkInvalid(feldname) {
            if (Object.prototype.hasOwnProperty.call(this.feedback, feldname)) {
                return true;
            }
            return false;
        },
        getInvalidMessage(feldname) {
            if (Object.prototype.hasOwnProperty.call(this.feedback, feldname)) {
                return this.feedback[feldname];
            }
            return "";
        },
    },
}
</script>
