<template>
  <div id="all">
    <div id="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- breadcrumb-->
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="nav-link">Home</router-link>
                </li>
                <li aria-current="page" class="breadcrumb-item active">
                  Bestellung
                </li>
              </ol>
            </nav>
          </div>
          <div id="checkout-anmeldung" class="col-lg-12">
            <div class="box">
              <!--<form>-->
              <!-- <h1>Bestellung</h1> -->
              <div id="checkout-nav" class="nav flex-column flex-md-row nav-pills text-center">
                <div
                  class="nav-link flex-sm-fill text-sm-center checkout-link"
                  v-bind:class="classNavLink('BestellungAnmeldung')"
                  ><i class="fa fa-user checkout-icon"></i>Anmeldung</div>
                <div class="nav-link flex-sm-fill text-sm-center checkout-link"
                  v-bind:class="classNavLink('BestellungAdresse')"
                >
                  <i class="fa fa-map-marker checkout-icon"></i>Adresse</div>
                <div class="nav-link flex-sm-fill text-sm-center checkout-link"
                  v-bind:class="classNavLink('BestellungLiefermethode')"
                >
                  <i class="fa fa-truck checkout-icon"> </i>Liefermethode</div>
                <div class="nav-link flex-sm-fill text-sm-center checkout-link"
                  v-bind:class="classNavLink('BestellungZahlmethode')"
                >
                  <i class="fa fa-money checkout-icon"> </i>Zahlmethode</div>
                <div class="nav-link flex-sm-fill text-sm-center checkout-link"
                  v-bind:class="classNavLink('BestellungUebersicht')"
                >
                  <i class="fa fa-eye checkout-icon"> </i>Bestellübersicht</div>
              </div>
              <router-view></router-view>
              <!--</form>-->
            </div>
            <!-- /.box-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShopCheckout",
  methods: {
    isCheckoutSeite(seitenname) {
      return this.$route.name === seitenname;
    },
    classNavLink(seitenname) {
      return {
      "active": this.isCheckoutSeite(seitenname),
      "d-none": !this.isCheckoutSeite(seitenname),
      "d-md-block": !this.isCheckoutSeite(seitenname),
    };      
   },
  },
};
</script>
<style scoped>
.nav-link {
  padding: 0;
}
.checkout-link {
  padding: 0.6rem 0;
}
.checkout-icon {
  margin-right: 5px;
}
.clickicon {
  cursor: pointer;
}
.warenkorb-summe {
  padding: 3px 12px;
}
.warenkorb-versandkosten {
  border-top: 0px solid white;
  padding: 3px 12px;
}
.warenkorb-gesamtpreis {
  border-top: 1px solid #dee2e6;
  border-bottom: 5px double #dee2e6;
  padding: 3px 12px;
}

#checkout-nav {
  margin-bottom: 0.6rem;
}

.nav-pills .nav-link.active {
  border-radius: 5px;
  background: #fcf9f1;
  color: black;
  border: 1px solid black;
}
</style>
