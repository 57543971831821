<template>
<div class="login-page">
    <div class="container d-flex align-items-center position-relative py-5">
        <div class="card shadow-sm w-100 rounded overflow-hidden bg-none">
            <div class="card-body p-0">
                <div class="row gx-0 align-items-stretch">
                    <!-- Logo & Information Panel-->
                    <div class="col-lg-6">
                        <div class="info d-flex justify-content-center flex-column p-4">
                            <div class="py-1">
                                <h1>Kontakt</h1>
                            </div>
                        </div>
                    </div>
                    <!-- Form Panel    -->
                    <div class="col-lg-6 bg-white">
                        <div class="d-flex align-items-center px-4 px-lg-5 h-100">
                            <div>
                                <h3><i class="fa fa-map-marker"></i> Anschrift</h3>
                                <p>Ranu Hasuk<br>Bockrisweg 11<br>22761 Hamburg<br>Deutschland<br></p>
                                <p class="lead">Sie möchten uns etwas mitteilen?</p>
                                <p>Sie können uns gerne per E-Mail kontaktieren. Wir antworten in der Regel innerhalb von 24 Stunden.</p>
                                <h3><i class="fa fa-envelope"></i> E-Mail</h3>
                                <p><strong><a href="mailto:info@chang-asia-shop.de?subject=Mitteilung an Chang Asia Shop&body=Ihre Mitteilung:">info@chang-asia-shop.de</a></strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ShopKontakt',
}
</script>
