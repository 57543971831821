import Vue from 'vue'
import Vuex from 'vuex'
import { getWarenkorbHash, saveWarenkorbHash, getWarenkorbKnown, setWarenkorbDaten } from '@/store/data.js'
import { deleteWarenkorb, deleteAnmeldung, getAnmeldedaten, setAnmeldedaten } from '@/store/data.js'
import keyparameter from './modules/keyparameter.store'
import bestellung from './modules/bestellung.store'
import { serviceLoadKundenhash, serviceLoadKomplett } from "@/api/bestellung.api.js";
import { serviceWarenkorbAktion } from "@/api/warenkorb.api.js";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    "angemeldet": false,
    "benutzername": "",
    "kundenhash": "",
    "warenkorb": {
      "warenkorbhash": "",
      "anzahlartikel": 0,
      "gesamtpreis": 0,
      "umsatzsteuer_7": 0,
      "umsatzsteuer_19": 0,
      "summe": 0,
      "versandkosten": 0,
      "positionen": []
    },
  },
  getters: {
    isAngemeldet: state => {
      return state.angemeldet;
    },
    getWarenkorbHash: state => {
      return state.warenkorb.warenkorbhash;
    },
    getKundenHash: state => {
      return state.kundenhash;
    },
    /* Anzahl Artikel im Warenkorb */
    getWarenkorbAnzahl: state => {
      return state.warenkorb.anzahlartikel;
    },
    /* Liste der Positionen im Warenkorb */
    getWarenkorbPositionen: state => {
      return state.warenkorb.positionen;
    },
    /* Gesamtpreis des Warenkorbs */
    getWarenkorbGesamtpreis: state => {
      return state.warenkorb.gesamtpreis;
    },
    /* Summe aller Positionen im Warenkorb */
    getWarenkorbSumme: state => {
      return state.warenkorb.summe;
    },
    /* Versandkosten */
    getWarenkorbVersandkosten: state => {
      return state.warenkorb.versandkosten;
    },
    /* Benutzername */
    getBenutzername: state => {
      return state.benutzername;
    },
    getWarenkorbUmsatzsteuer7: state => {
      return state.warenkorb.umsatzsteuer_7;
    },
    getWarenkorbUmsatzsteuer19: state => {
      return state.warenkorb.umsatzsteuer_19;
    },
    getPakete: state => {
      return state.warenkorb.pakete;
    }
  },
  mutations: {
    /* Warenkorbinhalt und Warenkorbhash setzen */
    setWarenkorb(state, warenkorb) {
      state.warenkorb = warenkorb;
      saveWarenkorbHash(warenkorb.warenkorbhash);
    },
    /* nur den im Localstorage gespeicherten Warenkorbhash im Warenkorb-Objekt setzen */
    setWarenkorbHash(state, hashvalue) {
      state.warenkorb.warenkorbhash = hashvalue;
    },
    resetWarenkorb(state) {
      state.warenkorb.warenkorbhash = "";
      state.warenkorb.positionen = [];
      state.warenkorb.anzahlartikel = 0;
      state.warenkorb.gesamtpreis = 0;
      state.warenkorb.summe = 0;
      state.warenkorb.umsatzsteuer_19 = 0;
      state.warenkorb.umsatzsteuer_7 = 0;
      state.warenkorb.versandkosten = 0;
    },
    /* Anmeldezustand setzen */
    setAngemeldet(state, isAngemeldet) {
      state.angemeldet = isAngemeldet;
    },
    setBenutzername(state, benutzername) {
      state.benutzername = benutzername;
    },
    setKundenHash(state, kundenhash) {
      state.kundenhash = kundenhash;
    },
  },
  actions: {
    async providingHashvalues(context) {
      console.log("enter providing Hashvalues");
      if (typeof this.state.warenkorb.warenkorbhash == 'undefined' || !this.state.warenkorb.warenkorbhash || 
        typeof this.state.kundenhash == 'undefined' || !this.state.kundenhash) {
        console.log("warenkorbhash oder kundenhash ist undefined");
        await context.dispatch("initHashvalues");
      }
      let kundenhashExists = true;
      if (!this.state.kundenhash) {
        kundenhashExists = false;
      }
      return new Promise((resolve) => {
        console.log("leaving providing Hasvalues: kundenhashExists=" + kundenhashExists);
        resolve(kundenhashExists);  // Let the calling function know that http is done. You may send some data back
        });
    },
    resetBestellungAbgeschlossen({ commit }) {
      commit("bestellung/reset");
      commit("setKundenHash", "");  
      commit("resetWarenkorb");
      deleteWarenkorb();
    },
    abmelden({ commit }) {
          deleteWarenkorb();
          deleteAnmeldung();
          commit("resetWarenkorb");
          commit("setAngemeldet", false);
          commit("setKundenHash", "");
          commit("setBenutzername", "");
          console.log("Abmeldung erfolgt.");
    },
    anmelden({ commit }, anmeldedaten) {
      setAnmeldedaten(anmeldedaten.jwt);
      setWarenkorbDaten(1, anmeldedaten.warenkorbhash);
      commit("setBenutzername", anmeldedaten.benutzername);
      commit("setAngemeldet", true);
      this.dispatch("loadWarenkorb");
    },
    async loadSession(context) {
      let jwt = getAnmeldedaten();
      console.log("loadSession: " + jwt);
      if (!jwt) {
        console.log("loadSession: !jwt");
        context.commit("setAngemeldet", false);
        if (getWarenkorbKnown() === 1) {
          console.log("loadSession: !jwt AND warenkorbKnown === 1");
          context.commit("resetWarenkorb");
          deleteWarenkorb();
        }
      } else {
        console.log("call checkSession");
        await context.dispatch("checkSession", jwt);
        console.log("dispatch checkSession then");
      }
    },
    async checkSession(context, data) {
      await this._vm.axios.post('./api/checksession.php', {}, { headers: { 'Authorization': "Bearer " + data } }).then(function (response) {
        console.log("checkSession: " + JSON.stringify(response.data));
        if (response.data.successful) {
          console.log("checkSession return true");
          context.commit("setAngemeldet", true);
          context.commit("setBenutzername", response.data.benutzername);
        }
        else {
          console.log("checkSession return false");
          context.commit("setAngemeldet", false);
          context.commit("resetWarenkorb");
          deleteWarenkorb();
          deleteAnmeldung();
        }
      });
    },
    reloadBestellungFallsErforderlich(context, kundenhash) {
      return new Promise((resolve) => {
        if (!context.getters["bestellung/getVersandartId"]) {
          serviceLoadKomplett(kundenhash).then( response => {
            context.commit("bestellung/setAdressdaten", { rechnungsadresse: response.data.bestellung.kundendaten.rg, lieferadresse: response.data.bestellung.kundendaten.lfg, 
              rechnunggleichliefer: response.data.bestellung.kundendaten.rg_gleich_lfg });
            context.commit("bestellung/setVersandartId", response.data.bestellung.versandartid);
            context.commit("bestellung/setZahlungsartId", response.data.bestellung.zahlungsartid);      
            console.log("leaving reloadBestellungFallsErforderlich: RELOADING SUCCESSFUL");
            resolve(true);  // Let the calling function know that http is done. You may send some data back      
          })
        } else {
          console.log("leaving reloadBestellungFallsErforderlich: NO RELOADING");
          resolve(true);  // Let the calling function know that http is done. You may send some data back
        }
        });
    },
    async initHashvalues(context) {
      console.log("enter initHashvalues");
      let warenkorbhash = getWarenkorbHash();
      if (warenkorbhash) {
        context.commit("setWarenkorbHash", warenkorbhash);
        const response = await serviceLoadKundenhash(warenkorbhash)
          if (response.data.successful) {
            console.log("Kundenhash=" + response.data.kundenhash);
            context.commit("setKundenHash", response.data.kundenhash);
          }
      }
      console.log("leave initHashvalues");
    },
    /* Zentrale Action für Warenkorbaktion */
    warenkorbAktion({ commit }, serviceparams) {
      // serviceparams["warenkorbhash"] = getWarenkorbHash();
      // this._vm.axios.post('./api/wkaktion.php', serviceparams).then(function (response) {
      //   console.log("warenkorbAktion " + serviceparams.aktion + " SUCCESS");
      //   commit("setWarenkorb", response.data);
      // }).catch(function (error) {
      //   console.log("warenkorbAktion " + serviceparams.aktion + " ERROR");
      //   console.log(error);
      // });

      // serviceWarenkorbAktion(serviceparams.aktion, getWarenkorbHash(), serviceparams.parameter).then(function (response) {
      //   console.log("warenkorbAktion " + serviceparams.aktion + " SUCCESS");
      //   commit("setWarenkorb", response.data);
      // }).catch(function (error) {
      //   console.log("warenkorbAktion " + serviceparams.aktion + " ERROR");
      //   console.log(error);
      // });

      return new Promise((resolve, reject) => {
        // Do something here... lets say, a http call using vue-resource
        serviceWarenkorbAktion(serviceparams.aktion, getWarenkorbHash(), serviceparams.parameter).then(response => {
            // http success, call the mutator and change something in state
            console.log("warenkorbAktion " + serviceparams.aktion + " SUCCESS");
            commit("setWarenkorb", response.data);
            resolve(response);  // Let the calling function know that http is done. You may send some data back
        }, error => {
            // http failed, let the calling function know that action did not work out
            console.log("warenkorbAktion " + serviceparams.aktion + " ERROR");
            console.log(error);
            reject(error);
        })
    });
    },
    /* Warenkorbinhalt laden */
    async loadWarenkorb(context) {
      console.log("vuex store loadWarenkorb");
      let serviceparams = { "aktion": "LOAD", "parameter": {} };
      await context.dispatch('warenkorbAktion', serviceparams);
    },
    /* Warenkorbinhalt laden und Versandkosten beruecksichtigen */
    loadWarenkorbMitVersandkosten(context, { lieferland , versandarttyp }) {
      console.log("vuex store loadWarenkorbMitVersandkosten");
      let inlandjanein = "DEU".localeCompare(lieferland) == 0;
      let berechnenjanein = "shipping".localeCompare(versandarttyp) == 0;
      let serviceparams = { "aktion": "LOAD", "parameter": {"versandkosten": {"versandkosten": 0, "berechnen": berechnenjanein, "inland": inlandjanein} }};
      context.dispatch('warenkorbAktion', serviceparams);
    },
    /* Artikel zum Warenkorb hinzufügen */
    addToWarenkorb(context, { artikelid, menge, bruttopreis }) {
      console.log("addToWarenkorb " + artikelid + " / " + menge + " / " + bruttopreis);
      let serviceparams = { "aktion": "ADD" , "parameter": {"artikelid" : artikelid, "menge" : menge, "bruttopreis" : bruttopreis}};
      // serviceparams["artikelid"] = artikelid;
      // serviceparams["menge"] = menge;
      // serviceparams["bruttopreis"] = bruttopreis;
      
      //context.dispatch('warenkorbAktion', serviceparams);

      return new Promise((resolve, reject) => {
        context.dispatch('warenkorbAktion', serviceparams).then(response => {
            resolve(response);  // Let the calling function know that http is done. You may send some data back
        }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
        })
    });
    },
    /* Menge eines Artikels im Warenkorb ersetzen */
    ersetzeWarenkorbPosition(context, { artikelid, menge, bruttopreis }) {
      console.log("ersetzeWarenkorbPosition " + artikelid + " / " + menge + " / " + bruttopreis);
      let serviceparams = { "aktion": "RPL" , "parameter": {"artikelid" : artikelid, "menge" : menge, "bruttopreis" : bruttopreis}};
      // serviceparams["artikelid"] = artikelid;
      // serviceparams["menge"] = menge;
      // serviceparams["bruttopreis"] = bruttopreis;
      //context.dispatch('warenkorbAktion', serviceparams);
      return new Promise((resolve, reject) => {
        context.dispatch('warenkorbAktion', serviceparams).then(response => {
            resolve(response);  // Let the calling function know that http is done. You may send some data back
        }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
        })
    });
    },
    /* Artikel im Warenkorb entfernen */
    entferneWarenkorbPosition(context, { artikelid }) {
      console.log("entferneWarenkorbPosition " + artikelid);
      let serviceparams = { "aktion": "DEL", "parameter": {"artikelid" : artikelid }};
      // serviceparams["artikelid"] = artikelid;
      //context.dispatch('warenkorbAktion', serviceparams);
      return new Promise((resolve, reject) => {
        context.dispatch('warenkorbAktion', serviceparams).then(response => {
            resolve(response);  // Let the calling function know that http is done. You may send some data back
        }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
        })
    });
    }
  },
  modules: {
    keyparameter, bestellung
  }
})
