export const produktmethods = {
    methods: {
        addToWarenkorb() {
            let self = this;
            this.$store.dispatch("addToWarenkorb", {
                artikelid: this.produkt.id,
                menge: 1,
                bruttopreis: this.produkt.bruttopreis,
            }).then(function () {
                self.$notify({
                    group: "warenkorb",
                    text: self.produkt.name + " in den Warenkorb.",
                });
            }).catch(err => {console.log(err)});

        },
        lieferzeitfarbe() {
            return {
                'text-success': (this.produkt.verfuegbar) > 0, 
                'text-danger': (this.produkt.verfuegbar) <= 0
            }
        },
        isLieferbar() {
            return (this.produkt.verfuegbar) > 0;
        }
    },
    computed: {
        isSale() {
            return this.produkt.verkaufmerkmal === "SALE";
        },
        isNeu() {
            return this.produkt.verkaufmerkmal === "NEU";
        },
        vergleichspreis() {
            return Math.round((this.produkt.bruttopreis / (this.produkt.nettoinhalt / 10)) * this.produkt.vergleichswert);
        },
        vergleichseinheit() {
            let newEinheit = this.produkt.nettoinhalteinheit;
            if (this.produkt.vergleichswert >= 1000) {
                if (this.produkt.nettoinhalteinheit === "g") {
                    newEinheit = "kg";
                } else {
                    newEinheit = "l";
                }
                return newEinheit;
            }
            return this.produkt.vergleichswert + " " + newEinheit;
        },
        labelnettoinhalt() {
            return this.produkt.nettoinhalteinheit === "g"
                ? "Nettogewicht"
                : "Nettoinhalt";
        },
        nettoinhalteinheit() {
            let newEinheit = this.produkt.nettoinhalteinheit;
            if (this.produkt.nettoinhalt >= 10000) {
                if (this.produkt.nettoinhalteinheit === "g") {
                    newEinheit = "kg";
                } else {
                    newEinheit = "l";
                }
            }
            return newEinheit;
        },
        lieferzeit() {
            if (this.isLieferbar()) {
                return "Lieferzeit 2-3 Tage*";
            }
            return "Zur Zeit nicht lieferbar";
        }
    }
}