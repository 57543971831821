import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import store from './store'
import Notifications from 'vue-notification'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

// require('./assets/css/style.violet.css')
require('./assets/css/style.blue.css')
require('./assets/css/custom.css')
require('./assets/vendor/font-awesome/css/font-awesome.min.css')

Vue.config.productionTip = false
Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VueToast, {
  // One of the options
  position: 'top'
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
